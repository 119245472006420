import { useCallback, useEffect, useMemo } from "react"
import { NavigateOptions, useSearchParams } from "react-router-dom"
import { Global } from "@emotion/react"
import { useSessionState } from "../../../../reactor/Web"
import { Email, PhoneNumber, Uuid } from "../../../../reactor"
import { MustacheString } from "../../../../packages/editing/Mustache"
import { Localized } from "../../../../packages/localization/Localized"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Section } from "../../../../packages/editing/Section"
import { TradeInStepProps } from "./checkout/TradeInStep"
import { InsuranceStepProps } from "./checkout/InsuranceStep"
import { LoginStepProps } from "./checkout/LoginStep"
import { ShippingStepProps } from "./checkout/ShippingStep"
import { PaymentStepProps } from "./checkout/PaymentStep"
import { ConfirmationStepProps } from "./checkout/ConfirmationStep"
import { CheckoutContext, CheckoutStep, checkoutSteps } from "./checkout/CheckoutContext"
import { checkoutNavigation } from "./checkout/CheckoutContext"
import {
    CurrencyAmount,
    getMe,
    GetPhoneOfferOptionsDto,
    GetShippingOptionsDto,
    GetShippingOptionsOptionsSlotsDto,
    GetTradeInFormDto,
    ManualShippingOption,
    PhoneOffer,
    PostTradeInFormsValueDto,
    TradeInPriceRequest,
    useInsuranceOptions,
    usePhoneOrderPrice,
    useTradeInForm,
} from "../client"
import { StepsNavigation } from "../ui/components/navigation/StepsNavigation"
import {
    DeviceCheckoutCard,
    DeviceCheckoutCardInfoItem,
} from "../ui/components/cards/DeviceCheckoutCard"
import { Flex } from "../ui/components/base/Flex"
import { css, responsiveCss, scaleValue } from "../ui/helpers/css"
import { colors } from "../ui/constants/colors"
import { LogoR } from "../ui/components/visual/LogoR"
import { TrustpilotStar } from "../ui/components/visual/TrustpilotStars"
import { Text } from "../ui/components/typography/Text"
import { ShippingOption } from "../../api/ShippingAPI"

function useCurrentPhoneOffer() {
    return useSessionState<PhoneOffer | undefined>("offer", undefined)
}

export function usePhoneOfferOptions() {
    return useSessionState<GetPhoneOfferOptionsDto | undefined>(
        "currentPhoneOfferOptions",
        undefined
    )
}

/**
 * Hooks for trade-in states.
 */
export function useCheckoutTradeInForm() {
    return useSessionState<GetTradeInFormDto | undefined>("checkoutTradeInForm", undefined)
}
export function useCheckoutTradeInPriceRequest() {
    return useSessionState<TradeInPriceRequest | undefined>(
        "checkoutTradeInPriceRequest",
        undefined
    )
}
export function useCheckoutTradeInResult() {
    return useSessionState<PostTradeInFormsValueDto | undefined>("checkoutTradeInResult", undefined)
}

function useCurrentShippingOption() {
    return useSessionState<
        { type: ShippingOption["type"]; id: Uuid<"ManualShippingSlot"> } | undefined
    >("shippingOption", undefined)
}

export type CheckoutShippingState = Partial<GetShippingOptionsDto> & {
    contactInfo?: {
        name?: string
        email?: Email
        phoneNumber?: PhoneNumber
    }
    address?: {
        street?: string
        unit?: string
        postalCode?: string
        region?: string
    }
    selectedOption?: ManualShippingOption
    selectedSlot?: GetShippingOptionsOptionsSlotsDto
}

/**
 * Single hook to hold shipping state.
 */
export function useCheckoutShippingState() {
    return useSessionState<CheckoutShippingState>("checkoutShippingState", {
        options: [],
        selectedOption: undefined,
        selectedSlot: undefined,
        contactInfo: undefined,
        address: undefined,
    })
}

/**
 * Stores which step in the checkout flow is the current step.
 */
function useCheckoutCurrentStep() {
    return useSessionState<CheckoutStep>("checkoutCurrentStep", "TradeIn")
}

/**
 * Used to store whether or not user need to log in during checkout flow. Is set based on
 * login state when the checkout flow is entered.
 */
function useCheckoutSkipAuthStep() {
    return useSessionState<boolean | undefined>("checkoutSkipAuthStep", undefined)
}

/**
 * State that will hold the order id once it is set. Is used to prevent order being posted
 * several times by checking if this state is already set.
 */
export function useCheckoutOrderId() {
    return useSessionState<Uuid<"PhoneOrder"> | undefined>("checkoutOrderId", undefined)
}

/**
 * Gets or sets the current phone offer. Setting resets the checkout flow and clears
 * such things as trade-in and shipping.
 */
export function useResetPhoneOffer(): [PhoneOffer | undefined, (phoneOffer: PhoneOffer) => void] {
    const [offer, setOffer] = useCurrentPhoneOffer()
    const [, setShippingOption] = useCurrentShippingOption()
    const [, setCheckoutCurrentStep] = useCheckoutCurrentStep()
    const [, setCurrentTradeInStep] = useSessionState<number | undefined>(
        "tradein-modal-step",
        undefined
    )
    const [, setTradeInResponses] = useSessionState<(Uuid<"TradeInResponse"> | undefined)[]>(
        "tradein-responses",
        []
    )
    const [, setTradeInResult] = useSessionState<PostTradeInFormsValueDto | undefined>(
        "tradein-result",
        undefined
    )
    const [, setTradeInModalOpen] = useSessionState("tradein-modal-open", false)
    const [, setSkipAuthStep] = useCheckoutSkipAuthStep()
    const [, setShippingState] = useCheckoutShippingState()

    const [, setCheckoutTradeInForm] = useCheckoutTradeInForm()
    const [, setCheckoutTradeInPriceRequest] = useCheckoutTradeInPriceRequest()
    const [, setCheckoutTradeInResult] = useCheckoutTradeInResult()
    const [, setCheckoutOrderId] = useCheckoutOrderId()

    return [
        offer,
        (phoneOffer: PhoneOffer) => {
            setCheckoutCurrentStep("TradeIn")
            setOffer(phoneOffer)
            setShippingState({})
            setCurrentTradeInStep(0)
            setTradeInResponses([])
            setTradeInResult(undefined)
            setTradeInModalOpen(false)
            setShippingOption(undefined)
            setSkipAuthStep(undefined)
            setCheckoutTradeInForm(undefined)
            setCheckoutTradeInPriceRequest(undefined)
            setCheckoutTradeInResult(undefined)
            setCheckoutOrderId(undefined)
        },
    ]
}

export type CheckoutDeviceCardProps = {
    /**
     * @default '{"no": "Pris per måned"}'
     */
    monthlyTotalPriceLabel: Localized<string>

    /**
     * @default '{"no": "Totalpris etter {{rentalPeriod}} er {{totalPrice}}"}'
     */
    rentalPeriodTotalPriceText: Localized<string>

    tradeInInfoItem: {
        /**
         * @default '{"en": "Trade-in cashback: {{cashback}}"}'
         */
        title: Localized<string>

        /**
         * @default '{"en": "We will deduct {{monthlyDiscount}} for the next {{discountPeriod}} months"}'
         */
        text: Localized<string>
    }
    insuranceInfoItem: {
        /**
         * @default '{"en": "Insurance is added", "no": "Mobilforsikring er lagt til"}'
         */
        title: Localized<string>

        /**
         * @default '{"no": "Du tegner {{insuranceName}} til {{amount}}"}'
         */
        text: Localized<string>
    }
    shippingInfoItem: {
        /**
         * Available variables are `{{shippingName}}` and `{{shippingDescription}}`.
         * @default '{"no": "{{shippingName}}"}'
         */
        title: Localized<string>

        /**
         * Available variables are `{{shippingName}}`, `{{shippingDescription}}`, and `{{shippingPrice}}`.
         * @default '{"no": "{{shippingPrice}} betales nå."}'
         */
        text: Localized<string>

        /**
         * If a separate text for free shipping is required it can be set here.
         * @default '{"no": "Gratis frakt!", "en": "Free shipping!"}'
         */
        freeShippingText?: Localized<string>
    }
}

function Checkout(section: {
    TradeIn: TradeInStepProps
    Insurance: InsuranceStepProps
    Login: LoginStepProps
    Shipping: ShippingStepProps
    Payment: PaymentStepProps
    Confirmation: ConfirmationStepProps
    CheckoutDeviceCard: CheckoutDeviceCardProps
}) {
    const localize = useLocalize()
    const [searchParams, setSearchParams] = useSearchParams()

    const [phoneOfferOptions] = usePhoneOfferOptions()
    const tradeInForm = useTradeInForm("Phones")

    const [currentStep, setCurrentStep] = useCheckoutCurrentStep()
    const [offer, setOffer] = useCurrentPhoneOffer()
    const [shippingOption] = useCurrentShippingOption()
    const [checkoutTradeInForm, setCheckoutTradeInForm] = useCheckoutTradeInForm()
    const [checkoutTradeInPriceRequest] = useCheckoutTradeInPriceRequest()
    const [checkoutTradeInResult] = useCheckoutTradeInResult()
    const [checkoutShippingState] = useCheckoutShippingState()
    const [skipAuthStep, setSkipAuthStep] = useCheckoutSkipAuthStep()

    const price = usePhoneOrderPrice(
        offer
            ? {
                  phone: offer,
                  shippingOption: shippingOption?.id,
                  tradeIn: checkoutTradeInPriceRequest,
              }
            : null
    )

    const formatAmount = useCallback(
        (a: CurrencyAmount) => {
            return `${a.majorUnits} ${localize({ no: a.currency })}`
        },
        [localize]
    )

    const currentStepBySearchParam: CheckoutStep | undefined = useMemo(() => {
        const stepParam = searchParams.get("s")
        const s = Object.keys(checkoutSteps).find((k) => {
            const stepProps = section[k as CheckoutStep]
            return localize(stepProps.slug) === stepParam
        })
        return s as CheckoutStep | undefined
    }, [localize, searchParams, section])

    // Sets the step search param "s" to the slug defined for the provided CheckoutStep.
    const setSearchParamStep = useCallback(
        (s: CheckoutStep, options?: NavigateOptions) => {
            const sp = Object.fromEntries(searchParams)
            delete sp.auth
            setSearchParams(
                {
                    ...sp,
                    s: localize(section[s].slug),
                },
                options
            )
        },
        [localize, searchParams, section, setSearchParams]
    )

    // It appears that it is a good idea to both set the step and update the search param, rather
    // than setting search param and relying on the effect to set current step by search param,
    // so use this function to set step.
    const setStep = useCallback(
        (s: CheckoutStep, options?: NavigateOptions) => {
            setCurrentStep(s)
            setSearchParamStep(s, options)
        },
        [setCurrentStep, setSearchParamStep]
    )

    // When first entering the checkout flow, the search param "s" might not be set. This effect
    // sets the param as a replacement, to ensure back button takes the user back to the previous
    // page and not checkout without the "s" search param.
    useEffect(() => {
        const auth = searchParams.get("auth")
        if (auth === "success") {
            setStep("Shipping")
        } else if (!currentStepBySearchParam) {
            setStep("TradeIn", { replace: true })
        }
    }, [currentStepBySearchParam, searchParams, setStep])

    // Effect to keep current step up to date with the step defined by the step search param "s".
    useEffect(() => {
        if (currentStepBySearchParam && currentStepBySearchParam !== currentStep) {
            setCurrentStep(currentStepBySearchParam)
        }
    }, [currentStepBySearchParam, setCurrentStep, currentStep])

    useEffect(() => {
        if (skipAuthStep === undefined) {
            async function setSkipAuthIfLoggedIn() {
                const checkMe = await getMe()
                setSkipAuthStep(!checkMe.anonymous)
            }

            void setSkipAuthIfLoggedIn()
        }
    }, [skipAuthStep, setSkipAuthStep])

    const insuranceOptions = useInsuranceOptions(
        offer?.model ?? null,
        offer?.storage ?? null,
        offer?.color ?? null,
        offer?.rentalPeriod ?? null
    )
    const purchasedInsurance = useMemo(
        () =>
            offer?.insurance
                ? insuranceOptions.data?.find((io) => io.id === offer.insurance)
                : null,
        [insuranceOptions.data, offer?.insurance]
    )

    const deviceCheckoutCardInfoItems = useMemo(() => {
        const infoItems = []
        if (offer) {
            const ii: DeviceCheckoutCardInfoItem = {
                icon: "device",
                title: localize(offer.name),
                text: [
                    localize(
                        phoneOfferOptions?.colors.find((c) => c.name === offer.color)
                            ?.displayName ?? {}
                    ),
                    localize(
                        phoneOfferOptions?.storageSizes.find((ss) => ss.name === offer.storage)
                            ?.displayName ?? {}
                    ),
                    `${offer.rentalPeriod} ${localize({ no: "måneder", en: "months" })}`,
                ].join(" · "),
            }
            infoItems.push(ii)
        }

        if (checkoutTradeInPriceRequest && checkoutTradeInResult) {
            const ii: DeviceCheckoutCardInfoItem = {
                icon: "handCoins",
                title: MustacheString(localize(section.CheckoutDeviceCard.tradeInInfoItem.title), {
                    cashback: formatAmount(checkoutTradeInResult.value),
                }),
                text: MustacheString(localize(section.CheckoutDeviceCard.tradeInInfoItem.text), {
                    monthlyDiscount: formatAmount(checkoutTradeInResult.monthlyDiscount),
                    discountPeriod: `${checkoutTradeInResult.months.valueOf()}`,
                }),
            }
            infoItems.push(ii)
        }
        if (offer?.insurance && price.data?.monthlyCost.insurance && purchasedInsurance) {
            const ii: DeviceCheckoutCardInfoItem = {
                icon: "shield",
                title: localize(section.CheckoutDeviceCard.insuranceInfoItem.title),
                text: MustacheString(localize(section.CheckoutDeviceCard.insuranceInfoItem.text), {
                    insuranceName: localize(purchasedInsurance.displayName),
                    amount: `${formatAmount(price.data.monthlyCost.insurance)}/${localize({ no: "md", en: "mo" })}`,
                }),
            }

            infoItems.push(ii)
        }
        if (
            checkoutShippingState.selectedOption &&
            checkoutShippingState.contactInfo &&
            checkoutShippingState.address
        ) {
            const ii: DeviceCheckoutCardInfoItem = {
                icon: "truck",
                title: localize(checkoutShippingState.selectedOption.name),
                text: MustacheString(
                    !checkoutShippingState.selectedOption.cost.valueOf() &&
                        section.CheckoutDeviceCard.shippingInfoItem.freeShippingText
                        ? localize(section.CheckoutDeviceCard.shippingInfoItem.freeShippingText)
                        : localize(section.CheckoutDeviceCard.shippingInfoItem.text),
                    {
                        shippingPrice: formatAmount({
                            minorUnits: checkoutShippingState.selectedOption.cost * 10,
                            majorUnits: checkoutShippingState.selectedOption.cost,
                            currency: "NOK",
                        }),
                        shippingName: localize(checkoutShippingState.selectedOption.name),
                        shippingDescription: localize(
                            checkoutShippingState.selectedOption.description
                        ),
                    }
                ),
            }
            infoItems.push(ii)
        }

        return infoItems
    }, [
        checkoutShippingState.address,
        checkoutShippingState.contactInfo,
        checkoutShippingState.selectedOption,
        checkoutTradeInPriceRequest,
        checkoutTradeInResult,
        formatAmount,
        localize,
        offer,
        phoneOfferOptions?.colors,
        phoneOfferOptions?.storageSizes,
        price.data?.monthlyCost.insurance,
        purchasedInsurance,
        section.CheckoutDeviceCard,
    ])

    useEffect(() => {
        if (tradeInForm.data && !checkoutTradeInForm) {
            setCheckoutTradeInForm(tradeInForm.data)
        }
    }, [checkoutTradeInForm, setCheckoutTradeInForm, tradeInForm.data])

    if (!offer || skipAuthStep === undefined) return <></>

    // If current step is not up to date with search param step, wait with rendering until they
    // are aligned to prevent flash of incorrect step.
    if (currentStepBySearchParam !== currentStep) return <></>

    if (price.error) {
        return <div>{price.error.detail}</div>
    }

    const StepContent: any = checkoutSteps[currentStep]

    return (
        <CheckoutContext.Provider
            value={{
                props: {
                    TradeIn: section.TradeIn,
                    Insurance: section.Insurance,
                    Login: section.Login,
                    Shipping: section.Shipping,
                    Payment: section.Payment,
                    Confirmation: section.Confirmation,
                },
                insuranceOptions,
                skipAuthStep,
                offer,
                setOffer,
                price: price.data,
                step: currentStep,
                setStep,
            }}
        >
            <Global styles={{ body: { backgroundColor: colors.gray100 } }} />
            <div
                css={css(
                    {
                        display: "grid",
                        gridTemplateAreas: '"logo" "progress" "content" "device"',
                        gap: 16,
                        paddingTop: 16,
                        marginBottom: 16,
                    },
                    responsiveCss("min", "md", {
                        gridTemplateAreas: '"progress logo" "content device"',
                        gridTemplateColumns: "1fr 0.68fr",
                        gap: 24,
                        paddingTop: 24,
                        marginBottom: 24,
                    })
                )}
            >
                <Flex gap={8} style={{ gridArea: "logo", minWidth: 0 }} justifyContent="flex-end">
                    <Flex
                        css={css({ height: scaleValue(48), borderRadius: 100 })}
                        alignItems="center"
                        padding={{ left: scaleValue(24), right: scaleValue(16) }}
                        gap={8}
                        backgroundColor="grayWhite"
                    >
                        <Text variant="heading" level="4">
                            4,8
                        </Text>
                        <TrustpilotStar />
                    </Flex>
                    <LogoR style={{ height: scaleValue(48) }} />
                </Flex>
                <div style={{ gridArea: "progress", minWidth: 0 }}>
                    <StepsNavigation
                        steps={
                            skipAuthStep
                                ? checkoutNavigation.filter((s) => s.name !== "Login")
                                : checkoutNavigation
                        }
                        currentStep={currentStep}
                    />
                </div>
                <div style={{ gridArea: "device", minWidth: 0 }}>
                    {offer.images && offer.images[0] ? (
                        <div
                            style={{
                                position: "sticky",
                                top: 24,
                            }}
                        >
                            <DeviceCheckoutCard
                                subscription={{ duration: offer.rentalPeriod, unit: "months" }}
                                checkout={{
                                    currency: "NOK",
                                    originalPrice:
                                        (price.data?.monthlyCost.total.majorUnits ?? 0) +
                                        (price.data?.monthlyCost.tradeInDiscount?.majorUnits ?? 0),
                                    discountedPrice: price.data?.monthlyCost.total.majorUnits ?? 0,
                                    monthlyTotalPriceLabel: localize(
                                        section.CheckoutDeviceCard.monthlyTotalPriceLabel
                                    ),
                                    rentalPeriodTotalPriceText: MustacheString(
                                        localize(
                                            section.CheckoutDeviceCard.rentalPeriodTotalPriceText
                                        ),
                                        {
                                            rentalPeriod: `${offer.rentalPeriod.valueOf()} ${localize({ no: "md.", en: "mo." })}`,
                                            totalPrice: price.data
                                                ? `${formatAmount({ minorUnits: price.data.monthlyCost.total.minorUnits * offer.rentalPeriod.valueOf(), majorUnits: price.data.monthlyCost.total.majorUnits * offer.rentalPeriod.valueOf(), currency: price.data.monthlyCost.total.currency })}`
                                                : "",
                                        }
                                    ),
                                }}
                                infoItems={deviceCheckoutCardInfoItems}
                                product={{
                                    image: offer.images[0] as any,
                                    color: localize(
                                        phoneOfferOptions?.colors.find(
                                            (c) => c.name === offer.color
                                        )?.displayName ?? {}
                                    ),
                                    storageSize: localize(
                                        phoneOfferOptions?.storageSizes.find(
                                            (ss) => ss.name === offer.storage
                                        )?.displayName ?? {}
                                    ),
                                }}
                            />
                        </div>
                    ) : null}
                </div>
                <Flex
                    direction="column"
                    borderRadius="lg"
                    backgroundColor="grayWhite"
                    css={css(
                        {
                            "--padding-x": "16px",
                            padding: "20px var(--padding-x)",
                            gridArea: "content",
                            minWidth: 0,
                            borderRadius: 16,
                        },
                        responsiveCss("min", "md", {
                            "--padding-x": "80px",
                            padding: scaleValue(80),
                        })
                    )}
                >
                    <StepContent {...section[currentStep]} />
                </Flex>
            </div>
        </CheckoutContext.Provider>
    )
}
Section(Checkout)

import { Dispatch, PropsWithChildren, useCallback, useEffect, useReducer, useState } from "react"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { useMe, useShippingOptions } from "../../client"
import { Heading } from "../../ui/components/typography/Heading"
import { TextInput } from "../../ui/components/controllers/TextInput"
import { RadioButton } from "../../ui/components/controllers/Radio"
import { Checkbox } from "../../ui/components/controllers/Checkbox"
import { Button } from "../../ui/components/buttons/Button"
import { useCheckoutContext } from "./CheckoutContext"
import { css, responsiveCss, scaleValue } from "../../ui/helpers/css"
import { Flex } from "../../ui/components/base/Flex"
import { colors } from "../../ui/constants/colors"
import { Email, Markdown, PhoneNumber, Uuid } from "../../../../../reactor"
import { Localized } from "../../../../../packages/localization/Localized"
import { IconName } from "../../ui/components/visual/Icon"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { NextStepsBlock } from "../../ui/components/blocks/NextStepsBlock"
import { Text } from "../../ui/components/typography/Text"
import { Divider } from "../../ui/components/other/Divider"
import { useCheckoutShippingState } from "../Checkout"

type ContactInfo = {
    name?: {
        /**
         * @default '{"en": "Name", "no": "Navn"}'
         */
        label: Localized<string>
        group?: number
        order?: number
    }
    email?: {
        /**
         * @default '{"en": "Email", "no": "Epostadresse"}'
         */
        label: Localized<string>
        group?: number
        order?: number
        type?: "email"
    }
    phoneNumber?: {
        /**
         * @default '{"en": "Phone", "no": "Telefonnummer"}'
         */
        label: Localized<string>
        group?: number
        order?: number
        type?: "tel"
    }
}

type Address = {
    street?: {
        /**
         * @default '{"en": "Street", "no": "Gateadresse"}'
         */
        label: Localized<string>
        group?: number
        order?: number
    }

    unit?: {
        /**
         * @default '{"en": "Unit", "no": "Enhet"}'
         */
        label: Localized<string>
        group?: number
        order?: number
    }
    region?: {
        /**
         * @default '{"en": "City", "no": "Poststed"}'
         */
        label: Localized<string>
        group?: number
        order?: number
    }
    postalCode?: {
        /**
         * @default '{"en":"Postal Code", "no": "Postnummer"}'
         */
        label: Localized<string>
        group?: number
        order?: number
    }
}

type FormKey = keyof ContactInfo | keyof Address | "countryCode"

type FormField = {
    label: Localized<string>
    formKey: FormKey
    group: number
    order: number
    type: "text" | "tel" | "email"
}

type NextSteps = {
    readonly id: Uuid
    /**
     * @title
     */
    text: Localized<Markdown>
    icon: IconName
}
type State = Record<FormKey | "countryCode", { value: string; valid?: boolean; dirty?: boolean }>

type Action =
    | { type: keyof State; payload: string }
    | { type: "SET_ALL"; payload: Record<keyof State, string> }

const initialState: State = {
    name: { value: "" },
    email: { value: "" },
    phoneNumber: { value: "" },
    street: { value: "" },
    unit: { value: "" },
    region: { value: "" },
    postalCode: { value: "" },
    countryCode: { value: "" },
}

function formReducer(state: State, action: Action): State {
    if (action.type === "email") {
        try {
            Email(action.payload)
            return { ...state, email: { value: action.payload, valid: true, dirty: true } }
        } catch (e) {
            return { ...state, email: { value: action.payload, valid: false, dirty: true } }
        }
    }
    if (action.type === "phoneNumber") {
        try {
            PhoneNumber(action.payload)
            return { ...state, phoneNumber: { value: action.payload, valid: true, dirty: true } }
        } catch (e) {
            return { ...state, phoneNumber: { value: action.payload, valid: false, dirty: true } }
        }
    }
    if (action.type !== "SET_ALL") {
        return {
            ...state,
            [action.type]: { value: action.payload, dirty: true, valid: !!action.payload },
        }
    } else {
        return {
            ...state,
            ...Object.keys(action.payload).reduce((acc, key) => {
                acc[key as FormKey] = {
                    value: action.payload[key as FormKey],
                    valid: true,
                    dirty: true,
                }
                return acc
            }, {} as Partial<State>),
        }
    }
}

export type ShippingStepProps = {
    /**
     * Name of this step, used in checkout step navigation bar, and possibly other places the
     * step is referenced, like the summary page.
     *
     * @default '{"en": "Shipping", "no": "Levering"}'
     */
    name: Localized<string>

    /**
     * Shipping step identifier to use in the URL.
     * @default '{"en": "shipping", "no": "levering"}'
     */
    slug: Localized<string>

    /**
     * @default '{"en": "Shipping"}'
     */
    pageHeading: Localized<string>

    /**
     * @default '{"en": "Personal information", "no": "Personopplysninger"}'
     */
    personalInformationHeading: Localized<string>
    /**
     * @default '{"en": "Delivery address", "no": "Leveringsadresse"}'
     */
    addressHeading: Localized<string>
    /**
     * @default '{"en": "Delivery options", "no": "Leveringsalternativer"}'
     */
    deliveryOptionsHeading: Localized<string>
    /**
     * @default '{"en": "Unfortunately we have no delivery options available for your address", "no": "Vi har dessverre ingen leveringsalternativer til din adresse"}
     */
    noDeliveryOptions: Localized<string>

    /**
     * @default '{"en": "No available times", "no": "Ingen tilgjengelige tider"}
     */
    noDeliverySlots: Localized<string>

    marketingCheckbox: Localized<Markdown>
    termsCheckbox: Localized<Markdown>

    /**
     * @default '{"en": "Proceed to payment", "no": "Gå til betaling"}'
     */
    nextStep: Localized<string>
    contactInfo: ContactInfo
    address: Address
    nextStepsHeading: Localized<string>
    nextSteps: NextSteps[]
}
export function ShippingStep({
    contactInfo: c,
    address: a,
    nextSteps,
    pageHeading,
    personalInformationHeading,
    addressHeading,
    deliveryOptionsHeading,
    noDeliveryOptions,
    noDeliverySlots,
    marketingCheckbox,
    termsCheckbox,
    nextStep,
    nextStepsHeading,
}: ShippingStepProps) {
    const me = useMe()
    const localize = useLocalize()
    const { setStep } = useCheckoutContext()
    const [shipping, setShipping] = useCheckoutShippingState()

    useEffect(() => {
        dayjs.extend(advancedFormat)
    }, [])

    const [state, dispatch] = useReducer(formReducer, initialState)
    const validate = useCallback(
        () =>
            !Object.keys(state).some(
                (key) => !state[key as FormKey].valid || !state[key as FormKey].dirty
            ),
        [state]
    )

    const contactInfo = groupAndSort(c)
    const address = groupAndSort(a)
    const shippingOptions = useShippingOptions("NO", state.postalCode.value || null)

    useEffect(
        () => setShipping({ ...shipping, options: shippingOptions.data?.options ?? [] }),
        [setShipping, shipping, shippingOptions, shippingOptions.data]
    )

    useEffect(() => {
        if (!me.data) return
        const [street, unit] = me.data.address?.street.split("\n") ?? []

        dispatch({
            type: "SET_ALL",
            payload: {
                name: `${me.data.givenName} ${me.data.familyName}`,
                email: me.data.email?.valueOf() ?? "",
                phoneNumber: me.data.phoneNumber
                    ? me.data.phoneNumber.valueOf().startsWith("+")
                        ? me.data.phoneNumber.valueOf()
                        : "+" + me.data.phoneNumber.valueOf()
                    : "",
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                street: street ?? "",
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                unit: unit ?? "",
                countryCode: me.data.address?.country.valueOf() ?? "",
                postalCode: me.data.address?.zip ?? "",
                region: me.data.address?.city ?? "",
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(me.data)])

    const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
    const [marketingAccepted, setMarketingAccepted] = useState<boolean>(false)

    return (
        <div>
            <Flex direction="column" css={css({ gap: 32 })}>
                <span css={css(responsiveCss("max", "sm", { display: "none" }))}>
                    <Heading level={2}>{localize(pageHeading)}</Heading>
                </span>
                <SectionHeading title={localize(personalInformationHeading)}>
                    <Flex direction="column" gap={8}>
                        {contactInfo.map((g) => {
                            if (Array.isArray(g)) {
                                return (
                                    <InputGroup
                                        key={g.join("")}
                                        group={g}
                                        state={state}
                                        localize={localize}
                                        dispatch={dispatch}
                                    ></InputGroup>
                                )
                            } else {
                                return (
                                    <TextInput
                                        type={g.type}
                                        label={localize(g.label)}
                                        key={g.formKey}
                                        value={state[g.formKey].value}
                                        onChange={(e) =>
                                            dispatch({
                                                type: g.formKey,
                                                payload: e.target.value,
                                            })
                                        }
                                        state={setFormFieldState(state, g.formKey)}
                                    />
                                )
                            }
                        })}
                    </Flex>
                </SectionHeading>
                <SectionHeading title={localize(deliveryOptionsHeading)}>
                    <Flex
                        borderRadius="md"
                        direction="column"
                        css={css(
                            responsiveCss("min", "md", {
                                padding: scaleValue(40),
                                background: colors.gray100,
                                border: "1px solid",
                                borderColor: colors.gray200,
                            })
                        )}
                    >
                        {shipping.options?.length === 0 ? (
                            <Text variant="body" size="md">
                                {localize(noDeliveryOptions)}
                            </Text>
                        ) : (
                            shipping.options?.map((option, index) => (
                                <Flex
                                    key={localize(option.name)}
                                    direction="column"
                                    gap={24}
                                    style={{
                                        borderBottom:
                                            shipping.options &&
                                            index !== shipping.options.length - 1
                                                ? `1px solid ${colors.gray200}`
                                                : undefined,
                                    }}
                                    css={css(
                                        responsiveCss("max", "sm", {
                                            gap: 4,
                                        }),
                                        responsiveCss("min", "md", {
                                            gap: 24,
                                        })
                                    )}
                                >
                                    <Flex
                                        alignItems="center"
                                        css={css(
                                            responsiveCss("max", "sm", {
                                                padding: "8px 0",
                                            })
                                        )}
                                        gap={scaleValue(16)}
                                    >
                                        <RadioButton checked={true} />
                                        <Text variant="heading" level="4" color="brand">
                                            {localize(option.name)}
                                        </Text>
                                    </Flex>
                                    <Flex
                                        gap={8}
                                        css={css(
                                            responsiveCss("max", "sm", {
                                                flexWrap: "nowrap",
                                                overflowX: "auto",
                                                width: "calc(100% + var(--padding-x) + var(--padding-x))",
                                                transform:
                                                    "translateX(calc(var(--padding-x) * -1))",
                                                paddingLeft: "var(--padding-x)",
                                                paddingRight: "var(--padding-x)",
                                                minWidth: 0,
                                            }),
                                            { flexWrap: "wrap" }
                                        )}
                                    >
                                        {option.slots.map((slot) => (
                                            <Button
                                                size="sm"
                                                variant="option"
                                                selected={shipping.selectedSlot?.id === slot.id}
                                                key={slot.id.valueOf()}
                                                onClick={() =>
                                                    setShipping({
                                                        ...shipping,
                                                        selectedOption: option,
                                                        selectedSlot: slot,
                                                    })
                                                }
                                            >
                                                {dayjs(slot.date).format("dddd Do MMMM")},{" "}
                                                {slot.from} - {slot.to}
                                            </Button>
                                        ))}
                                        {option.slots.length === 0 && (
                                            <div style={{ padding: 32 }}>
                                                {localize(noDeliverySlots)}
                                            </div>
                                        )}
                                    </Flex>
                                </Flex>
                            ))
                        )}
                    </Flex>
                </SectionHeading>
                <SectionHeading title={localize(addressHeading)}>
                    <Flex direction="column" gap={8}>
                        {address.map((g) => {
                            if (Array.isArray(g)) {
                                return (
                                    <InputGroup
                                        key={g.join("")}
                                        group={g}
                                        state={state}
                                        localize={localize}
                                        dispatch={dispatch}
                                    ></InputGroup>
                                )
                            } else {
                                return (
                                    <TextInput
                                        label={localize(g.label)}
                                        key={g.formKey}
                                        value={state[g.formKey].value}
                                        onChange={(e) =>
                                            dispatch({
                                                type: g.formKey,
                                                payload: e.target.value,
                                            })
                                        }
                                        state={setFormFieldState(state, g.formKey)}
                                    />
                                )
                            }
                        })}
                    </Flex>
                </SectionHeading>
                {nextSteps.length > 0 && (
                    <SectionHeading title={localize(nextStepsHeading)}>
                        <NextStepsBlock
                            steps={nextSteps.map((s) => ({
                                id: s.id.valueOf(),
                                icon: s.icon,
                                text: localize(s.text),
                            }))}
                        />
                    </SectionHeading>
                )}
                <div>
                    <Checkbox
                        checked={marketingAccepted}
                        onChange={(e) => setMarketingAccepted(!marketingAccepted)}
                        label={localize(marketingCheckbox)}
                    />
                    <Divider horizontal spacing={24} />
                    <Checkbox
                        checked={termsAccepted}
                        onChange={() => setTermsAccepted(!termsAccepted)}
                        label={localize(termsCheckbox)}
                    />
                </div>
                <Button
                    variant="primary"
                    onClick={() => {
                        setShipping({
                            ...shipping,
                            contactInfo: {
                                name: state.name.value,
                                phoneNumber: PhoneNumber(state.phoneNumber.value),
                                email: Email(state.email.value),
                            },
                            address: {
                                street: state.street.value,
                                postalCode: state.postalCode.value,
                                region: state.region.value,
                            },
                        })
                        setStep("Payment")
                    }}
                    disabled={!validate() || !termsAccepted || !shipping.selectedOption}
                >
                    {localize(nextStep)}
                </Button>
            </Flex>
        </div>
    )
}

type SectionHeadingProps = { title: string }
function SectionHeading({ title, children }: PropsWithChildren<SectionHeadingProps>) {
    return (
        <>
            <div css={css(responsiveCss("min", "md", { display: "none" }))}>
                <Heading level={2} margin={{ bottom: 16 }}>
                    {title}
                </Heading>
                {children}
            </div>
            <div css={css(responsiveCss("max", "sm", { display: "none" }))}>
                <Heading level={3} margin={{ bottom: 16 }}>
                    {title}
                </Heading>
                {children}
            </div>
        </>
    )
}

type InputGroupProps = {
    group: FormField[]
    state: State
    localize: ReturnType<typeof useLocalize>
    dispatch: Dispatch<Action>
}
function InputGroup({ group, state, localize, dispatch }: InputGroupProps) {
    return (
        <Flex gap={8} css={css(responsiveCss("max", "sm", { flexDirection: "column" }))}>
            {group.map((field) => (
                <div style={{ flex: 1 }} key={field.formKey}>
                    <TextInput
                        type={field.type}
                        label={localize(field.label)}
                        value={state[field.formKey].value}
                        onChange={(e) =>
                            dispatch({
                                type: field.formKey,
                                payload: e.target.value,
                            })
                        }
                        state={setFormFieldState(state, field.formKey)}
                    />
                </div>
            ))}
        </Flex>
    )
}

const setFormFieldState = (state: State, key: FormKey) => {
    return state[key].dirty
        ? !state[key].valid
            ? "error"
            : state[key].value
              ? "completed"
              : undefined
        : undefined
}

function groupAndSort(data: Record<string, Partial<FormField>>) {
    const sortedItems: FormField[] = Object.keys(data)
        .map((key) => {
            const value = data[key]
            // TypeScript claims value is always falsy, but this is not the case.
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!value) return

            return {
                formKey: key,
                label: value.label,
                group: value.group ?? 0,
                order: value.order ?? 0,
                type: value.type,
            }
        })
        .filter(Boolean)

        .sort((a, b) => a!.order - b!.order) as FormField[]

    return sortedItems.reduce(
        (acc, item) => {
            if (!item.group) {
                acc.push(item)
            } else {
                const groupIndex = acc.findIndex(
                    (group) => Array.isArray(group) && group[0]?.group === item.group
                )
                if (groupIndex === -1) {
                    acc.push([item])
                } else if (Array.isArray(acc[groupIndex])) {
                    acc[groupIndex]?.push(item)
                }
            }
            return acc
        },
        [] as (FormField | FormField[])[]
    )
}

import { Fragment, useContext } from "react"
import { css } from "@emotion/react"
import { WebPageContext } from "../../../../packages/web/components/WebPage"
import { Section } from "../../../../packages/editing/Section"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { colors } from "../ui/constants/colors"
import { pageWidthCss, responsiveCss } from "../ui/helpers/css"
import { Text } from "../ui/components/typography/Text"
import { Flex } from "../ui/components/base/Flex"

function Breadcrumb(section: {}) {
    const pageContext = useContext(WebPageContext)
    const localize = useLocalize()

    return (
        <Flex
            justifyContent="center"
            css={css({
                borderTop: `1px solid ${colors.gray200}`,
                borderBottom: `1px solid ${colors.gray200}`,
                width: "100vw",
                marginLeft: "calc(50% - 50vw)",
            })}
        >
            <div
                style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                }}
                css={pageWidthCss()}
            >
                <Text
                    variant="body"
                    size="md"
                    color="gray300"
                    css={responsiveCss("min", "md", { fontWeight: 500 })}
                >
                    <Flex gap={8}>
                        {pageContext.breadcrumb.map((page, index) => (
                            <Fragment key={page.id.valueOf()}>
                                {index > 0 && "/"}{" "}
                                <div
                                    style={
                                        index === pageContext.breadcrumb.length - 1
                                            ? { color: colors.gray500 }
                                            : {}
                                    }
                                >
                                    {localize(page.title)}
                                </div>
                            </Fragment>
                        ))}
                    </Flex>
                </Text>
            </div>
        </Flex>
    )
}
Section("Breadcrumb", Breadcrumb)

import { createRef, ReactNode, RefObject, useCallback, useRef, MouseEvent } from "react"
import { Link } from "react-router-dom"
import { SerializedStyles } from "@emotion/react"
import { css, scaleValue } from "../../helpers/css"
import { Component } from "../../../../../../packages/editing/Component"
import {
    ResponsiveVariant,
    responsiveVariantsCss,
    variantCss,
    VariantStyles,
    responsiveHeadingSize,
} from "../../helpers/css"
import { colors } from "../../constants/colors"
import { Icon, IconName } from "../visual/Icon"

import { buttonIconOnlySizes, ButtonSize, buttonSizes } from "../../constants/sizes"
import { Spacing, spacingToCss } from "../base/Box"

const buttonVariants = [
    "primary",
    "secondary",
    "dark",
    "outlined",
    "select",
    "option",
    "profile",
] as const
type ButtonVariant = (typeof buttonVariants)[number]

const variants: VariantStyles<ButtonVariant> = {
    primary: {
        color: colors.grayWhite,
        background: colors.brand,
        "&:hover:not([disabled])": {
            color: colors.grayWhite,
            backgroundColor: colors.brandDark,
        },
        "&:focus": { color: colors.gray500, backgroundColor: colors.brandLight },
    },
    secondary: {
        color: colors.gray500,
        backgroundColor: colors.gray100,
        "&:hover:not([disabled])": {
            backgroundColor: colors.gray200,
        },
        "&:focus": {
            color: colors.gray500,
            borderWidth: 2,
            borderColor: colors.gray500,
        },
    },
    dark: {
        color: colors.grayWhite,
        backgroundColor: colors.gray500,
        "&:hover:not([disabled])": { color: colors.grayWhite, backgroundColor: colors.gray400 },
        "&:focus": { color: colors.gray500, backgroundColor: colors.gray200 },
    },
    outlined: {
        color: colors.gray500,
        borderColor: colors.gray200,
        "&:hover:not([disabled])": { borderColor: colors.gray400 },
        "&:focus": { borderColor: colors.gray500 },
    },
    select: {
        color: colors.gray500,
        borderColor: colors.gray200,
        "&[data-selected=false]": { borderWidth: 1 },
        "&:hover:not([disabled])": { borderColor: colors.brand },
        "&:focus": { color: colors.gray500, backgroundColor: colors.gray200 },
        "&[data-selected=true]": {
            borderWidth: 2,
            borderColor: colors.brand,
            backgroundColor: colors.brandLight,
        },
    },
    option: {
        color: colors.gray400,
        borderColor: colors.gray200,
        background: colors.grayWhite,
        paddingLeft: `${scaleValue(16)} !important`,
        paddingRight: `${scaleValue(16)} !important`,
        borderRadius: scaleValue(12),
        "&[data-selected=false]": { borderWidth: 1, fontWeight: 400 },
        "&:hover:not([disabled])": { borderColor: colors.brand },
        "&:focus": { color: colors.gray400, backgroundColor: colors.gray100 },
        "&[data-selected=true]": {
            borderColor: colors.brand,
            backgroundColor: colors.brandLight,
        },
    },
    profile: {
        color: colors.brand,
        backgroundColor: colors.brandLight,
    },
}

type ButtonProps<T extends "a" | "button" | "div" = "button"> = {
    as?: T

    variant?: ResponsiveVariant<ButtonVariant>

    /**
     * Whether the button is disabled or not.
     */
    disabled?: boolean

    /**
     * Whether the button is selected or not.
     * This only applies to the "select" variant.
     */
    selected?: boolean

    /**
     * Show an icon from the design system before the button text.
     */
    iconStart?: IconName

    /**
     * Show an icon from the design system after the button text.
     */
    iconEnd?: IconName

    /**
     * @default "md"
     */
    size?: ResponsiveVariant<ButtonSize>

    /**
     * Click handler for the button.
     * @reflection any
     */
    onClick?: (e: MouseEvent) => void

    /**
     * A URL to navigate to when the button is pressed. Passing a URL param renders the button as
     * an anchor element.
     */
    href?: string

    /**
     * Add spacing to other elements.
     */
    margin?: Spacing

    /**
     * Set to true if button should take up all width of parent element.
     */
    fullwidth?: boolean

    /**
     * @reflection any
     */
    children?: ReactNode

    /**
     * Only supported for gallery to add additional styles to present states like hover and focus
     * without actually hovering or focusing. Looking for other way to achieve this, so support
     * for this prop can be dropped at any time.
     *
     * @deprecated
     */
    className?: string
}

export function Button<T extends "a" | "button" | "div" = "button">(props: ButtonProps<T>) {
    const {
        as = "button",
        variant = "primary",
        iconStart,
        iconEnd,
        size = "md",
        onClick,
        href,
        margin,
        fullwidth,
        children,
        className,
        ...rest
    } = props

    const styles = css([
        {
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "background-color 0.30s",
            borderStyle: "solid",
            borderWidth: size === "md" ? 2 : 1,
            borderColor: "transparent",
            whiteSpace: "nowrap",
            width: fullwidth ? "100%" : "auto",
            borderRadius: "100vw",
            cursor: "pointer",
        },
        responsiveButtonCss<T>(variant, size, props),
        props.disabled && {
            backgroundColor: colors.gray100,
            color: colors.gray300,
            cursor: "default",
        },
        margin && spacingToCss("margin", margin),
        responsiveHeadingSize("4"),
    ])

    const ref = useRef<{
        a: RefObject<HTMLAnchorElement>
        button: RefObject<HTMLButtonElement>
        div: RefObject<HTMLDivElement>
    }>({
        button: createRef(),
        a: createRef(),
        div: createRef(),
    })

    const handleClick = useCallback(
        (e: MouseEvent) => {
            onClick?.(e)

            // Prevent buttons from being stuck in active state after click is handled. Needed two
            // refs due to type errors when trying to use the same for button and anchor.
            ref.current.a.current?.blur()
            ref.current.button.current?.blur()
            ref.current.div.current?.blur()
        },
        [onClick]
    )

    const commonProps = {
        css: styles,
        onClick: handleClick,
        className: className ? className : "",
        "data-selected": props.selected,
        disabled: props.disabled,
    }

    const commonChildren = (
        <>
            {iconStart && <Icon margin={children ? { right: 8 } : 0} icon={iconStart} />}
            {children}
            {iconEnd && <Icon margin={children ? { left: 8 } : 0} icon={iconEnd} />}
        </>
    )

    return href ? (
        <Link ref={ref.current.a} to={href} {...commonProps}>
            {commonChildren}
        </Link>
    ) : as === "button" ? (
        <button ref={ref.current.button} {...commonProps} {...rest}>
            {commonChildren}
        </button>
    ) : (
        <div ref={ref.current.div} {...commonProps} {...rest}>
            {commonChildren}
        </div>
    )
}

Component(Button as any, {
    name: "Button",
    gallery: {
        about: "Buttons communicate actions that users can take.",
        path: "Button",
        items: [
            ...(["Primary", "Secondary", "Dark", "Select"].map((item) => ({
                title: `${item} button`,
                variants: [
                    {
                        label: "Default",
                        props: {
                            variant: item.toLowerCase() as ButtonVariant,
                            children: "Kjøp nå",
                            iconEnd: item !== "Select" ? ("arrowRight" as IconName) : undefined,
                        },
                    },
                    {
                        label: "Inactive",
                        props: {
                            variant: item.toLowerCase() as ButtonVariant,
                            children: "Ipsum",
                            disabled: true,
                            iconStart: undefined,
                        },
                    },
                    {
                        label: "Hover",
                        props: {
                            variant: item.toLowerCase() as ButtonVariant,
                            children: "Dolor",
                            iconStart: undefined,
                        },
                        pseudo: ":hover" as const,
                    },
                    {
                        label: "Focus",
                        props: {
                            variant: item.toLowerCase() as ButtonVariant,
                            children: "Click me",
                            iconStart: undefined,
                        },
                        pseudo: ":focus" as const,
                    },
                    item === "Select"
                        ? {
                              label: "Selected",
                              props: {
                                  variant: item.toLowerCase() as ButtonVariant,
                                  selected: true,
                                  children: "Click me",
                                  iconStart: undefined,
                              },
                          }
                        : undefined,
                ],
            })) as any),
            {
                title: "Icon only",
                variants: [
                    {
                        props: { icon: "plus" },
                    },
                ],
            },
            {
                title: "Small",
                variants: [
                    {
                        props: {
                            children: "Kjøp nå",
                            iconEnd: "arrowRight" as IconName,
                            size: "sm" as ResponsiveVariant<ButtonSize>,
                        },
                    },
                ],
            },
            {
                title: "Media Query Responsive Variant",
                variants: [
                    {
                        props: {
                            variant: [
                                "dark",
                                ["min", "lg", "primary"],
                            ] as ResponsiveVariant<ButtonVariant>,
                            children: "Kjøp nå",
                            iconEnd: "arrowRight" as IconName,
                            size: [
                                "sm",
                                ["min", "md", "md"],
                                ["min", "xl", "md"],
                            ] as ResponsiveVariant<ButtonSize>,
                        },
                    },
                ],
            },
        ],
    },
})

export function responsiveButtonCss<T extends "a" | "button" | "div" = "button">(
    variant: ResponsiveVariant<ButtonVariant>,
    size: ResponsiveVariant<ButtonSize>,
    props?: ButtonProps<T>
): SerializedStyles {
    const sizes = props?.iconStart && !props?.children ? buttonIconOnlySizes : buttonSizes
    return css([variantCss(variants, variant), responsiveVariantsCss(sizes, size)])
}

import { createContext, useContext } from "react"
import { GetInsuranceOptionsDto, PhoneOffer, PhoneOrderPrice } from "../../client"
import { ConfirmationStep, ConfirmationStepProps } from "./ConfirmationStep"
import { InsuranceStep, InsuranceStepProps } from "./InsuranceStep"
import { LoginStep, LoginStepProps } from "./LoginStep"
import { PaymentStep, PaymentStepProps } from "./PaymentStep"
import { ShippingStep, ShippingStepProps } from "./ShippingStep"
import { TradeInStep, TradeInStepProps } from "./TradeInStep"
import { IconName } from "../../ui/components/visual/Icon"
import { SSRResource } from "../../../../../packages/ssr/SSRHook"

export const checkoutSteps = {
    TradeIn: TradeInStep,
    Insurance: InsuranceStep,
    Login: LoginStep,
    Shipping: ShippingStep,
    Payment: PaymentStep,
    Confirmation: ConfirmationStep,
}

export type CheckoutStep = keyof typeof checkoutSteps

export const checkoutNavigation: { name: CheckoutStep; icon: IconName; tooltip: string }[] = [
    {
        name: "TradeIn",
        icon: "handCoins",
        tooltip: "Bytte inn",
    },
    {
        name: "Insurance",
        icon: "shield",
        tooltip: "Forsikring",
    },
    {
        name: "Login",
        icon: "vipps",
        tooltip: "Vipps ID",
    },
    {
        name: "Shipping",
        icon: "truck",
        tooltip: "Shipping",
    },
    {
        name: "Payment",
        icon: "receipt",
        tooltip: "Check-out",
    },
]

export type CheckoutContext = {
    props: {
        TradeIn: TradeInStepProps
        Insurance: InsuranceStepProps
        Login: LoginStepProps
        Shipping: ShippingStepProps
        Payment: PaymentStepProps
        Confirmation: ConfirmationStepProps
    }
    offer: PhoneOffer
    setOffer: (offer: PhoneOffer) => void
    insuranceOptions: SSRResource<GetInsuranceOptionsDto[]>
    price?: PhoneOrderPrice
    step: CheckoutStep
    setStep: (step: CheckoutStep) => void
    skipAuthStep: boolean
}

export const CheckoutContext = createContext<CheckoutContext | undefined>(undefined)

export function useCheckoutContext(): CheckoutContext {
    const context = useContext(CheckoutContext)
    if (!context) throw new Error("CheckoutContext not found")
    return context
}

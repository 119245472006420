import { Route } from "react-router-dom"
import { css, pageWidthCss } from "./ui/helpers/css"

// Make generic types work with forwardRef
declare module "react" {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactNode | null
    ): (props: P & React.RefAttributes<T>) => React.ReactNode | null
}

import "./sections/CardsWithMoreInfo"
import "./sections/Carousel"
import "./sections/Footer"
import "./sections/FrequentlyAskedQuestions"
import "./sections/FullNotification"
import "./sections/Header"
import "./sections/PressLogos"
import "./sections/ProductDetails"
import "./sections/PromotedProducts"
import "./sections/PromotedReviews"
import "./sections/UniqueSellingPoints"
import "./sections/WaitingListSignupModal"
import "./sections/WaitingListTrigger"
import "./sections/Checkout"
import "./sections/AccountView"
import "./sections/OrderView"
import "./sections/CancelView"
import "./sections/Breadcrumb"

import "./ui/components/buttons/Button"
import "./ui/components/buttons/Dropdown"
import "./ui/components/carousel/HighlightCarousel"
import "./ui/components/typography/Heading"
import "./ui/components/cards/WithOverlay"
import "./ui/components/cards/SeeAllCard"
import "./ui/components/cards/DeviceCheckoutCard"
import "./ui/components/controllers/Checkbox"
import "./ui/components/controllers/TextInput"
import "./ui/components/controllers/BoxSelection"
import "./ui/components/controllers/LocaleSelect"
import "./ui/components/base/CarouselBase"
import "./ui/components/controllers/Radio"
import "./ui/components/blocks/BenefitsBlock"
import "./ui/components/blocks/Faq"
import "./ui/components/blocks/StepsBlock"
import "./ui/components/blocks/WhatsInTheBoxBlock"
import "./ui/components/modal/Modal"
import "./ui/components/modal/NavigationModal"
import "./ui/components/modal/WaitingListSignupModal"
import "./ui/components/carousel/PressLogosCarousel"
import "./ui/components/carousel/ReviewsCarousel"
import "./ui/components/carousel/ContinuousSlideCarousel"
import "./ui/components/carousel/CardsCarousel"
import "./ui/components/carousel/ProductCarousel"
import "./ui/components/forms/MultipleChoiceQuestion"
import "./ui/components/forms/PaymentSummary"

import { WebSite } from "../../../packages/web/components/WebSite"
import { globalStyles } from "./ui/helpers/css"
import { useAnalyticsLogger } from "../../../packages/analytics/useAnalyticsLogger"
import { pageSize } from "./ui/constants/sizes"
import { NewsletterUnsubscribePage } from "./pages/NewsletterUnsubscribePage"
import { LocaleKey } from "../../../packages/localization/Locale"
import { CookieConsentView } from "./ui/components/cookie-consent/CookieConsentView"

function sectionItemCss() {
    return css(
        {
            margin: "0 auto",
            // Override Bootstrap line height variable.
            "--bs-body-line-height": "1",
            lineHeight: "1",
        },
        pageWidthCss(),
        css`
            @media (max-width: ${pageSize.xs.maxWidth}px) {
                overflow-x: clip;
            }
        `
    )
}

export function App() {
    useAnalyticsLogger()

    return (
        <WebSite
            gallery={true}
            studio={true}
            globalStyles={globalStyles}
            EditableSectionsProps={{ itemCss: sectionItemCss }}
            renderCookieConsentView={() => <CookieConsentView />}
        >
            <Route
                path="/system/nyhetsbrev-meld-av"
                element={<NewsletterUnsubscribePage locale={LocaleKey.no} />}
            />
            <Route
                path="/system/en/newsletter-unsubscribe"
                element={<NewsletterUnsubscribePage locale={LocaleKey.en} />}
            />
        </WebSite>
    )
}

import { ChangeEvent } from "react"
import { Component } from "../../../../../../packages/editing/Component"
import { Flex } from "../base/Flex"
import { Button } from "../buttons/Button"
import { Text } from "../typography/Text"
import { responsiveCss, scaleValue } from "../../helpers/css"
import { Box } from "../base/Box"
import { css } from "@emotion/react"

export function MultipleChoiceQuestion<V>(props: {
    question: string
    description?: string
    options: { value: V; text: string; disabled?: boolean }[]
    onChange: (e: ChangeEvent<HTMLInputElement>, val: V) => void
    value: V
}) {
    return (
        <Flex direction="column">
            <Box margin={{ bottom: scaleValue(16) }}>
                <Text variant="body" size="md">
                    {props.question}
                </Text>
                {props.description ? (
                    <Text variant="body" size="sm" color="gray300">
                        {props.description}
                    </Text>
                ) : null}
            </Box>
            <fieldset>
                <div
                    role="radiocontainer"
                    style={{
                        display: "grid",

                        gap: 8,
                    }}
                    css={css(
                        {
                            gridTemplateColumns: "1fr",
                        },
                        responsiveCss("min", "sm", {
                            gridTemplateColumns:
                                props.options.length % 3 === 0 ? "1fr 1fr 1fr" : "1fr 1fr",
                        })
                    )}
                >
                    {props.options.map((o) => (
                        <div key={JSON.stringify(o.value)} style={{ position: "relative" }}>
                            <label style={{ position: "relative", width: "100%" }}>
                                <Button
                                    as="div"
                                    key={o.text}
                                    size="sm"
                                    variant="select"
                                    selected={props.value === o.value}
                                    fullwidth
                                >
                                    {o.text}
                                </Button>
                                <input
                                    type="radio"
                                    name={props.question}
                                    checked={props.value === o.value}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        height: "100%",
                                        width: "100%",
                                        opacity: "0",
                                        zIndex: -1,
                                    }}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        props.onChange(e, o.value)
                                    }}
                                />
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>
        </Flex>
    )
}

Component(MultipleChoiceQuestion as any, {
    name: "MultipleChoiceQuestion",
    gallery: {
        path: "Forms/MultipleChoiceQuestion",
        items: [
            {
                variants: [
                    {
                        props: (state = "foo", setState) => ({
                            label: "Which one?",
                            options: [
                                { value: "foo", text: "Foo" },
                                { value: "bar", text: "Bar" },
                                { value: "baz", text: "Baz" },
                            ],
                            value: state,
                            onChange: (e: ChangeEvent<HTMLInputElement>, val: string) => {
                                setState(val)
                            },
                        }),
                    },
                ],
            },
        ],
    },
})

import { useCallback, useEffect, useMemo, useState } from "react"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { Localized } from "../../../../../packages/localization/Localized"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { AdyenComponent } from "../../../../../packages/adyen/web/AdyenComponent"
import { CurrencyAmount, postPhoneOrder } from "../../client"
import { Heading } from "../../ui/components/typography/Heading"
import { Flex } from "../../ui/components/base/Flex"
import { useCheckoutContext } from "./CheckoutContext"
import { CheckoutStepSummaryBlock } from "../../ui/components/blocks/CheckoutStepSummaryBlock"
import { Assert, Markdown, Uuid } from "../../../../../reactor"
import { MustacheString } from "../../../../../packages/editing/Mustache"
import { Box } from "../../ui/components/base/Box"
import { MarkdownView } from "../../../../../packages/markdown-edit/MarkdownView"
import { Text } from "../../ui/components/typography/Text"
import {
    useCheckoutOrderId,
    useCheckoutShippingState,
    useCheckoutTradeInForm,
    useCheckoutTradeInPriceRequest,
    useCheckoutTradeInResult,
} from "../Checkout"

export type PaymentStepProps = {
    /**
     * Name of this step, used in checkout step navigation bar, and possibly other places the
     * step is referenced, like the summary page.
     *
     * @default '{"en": "Payment", "no": "Betaling"}'
     */
    name: Localized<string>

    /**
     * Payment step identifier to use in the URL.
     * @default '{"en": "payment", "no": "betaling"}'
     */
    slug: Localized<string>

    /**
     * @default '{"en": "Checkout", "no": "Betaling"}'
     */
    heading: Localized<string>

    /**
     * Text informing the user that entered payment details will be saved for future charges.
     * @default '{"no": "Vår betalingsløsning kommer til å lagre dine kortdetaljer for å kunne gjøre fremtidige trekk mot ditt abonnement automatisk. Ved å gjennomføre betalingen godtar du dette."}'
     */
    paymentDetailsWillBeStoredText: Localized<Markdown>

    /**
     * Device summary.
     */
    deviceSummary: {
        /**
         * @default '{"en": "Your phone", "no": "Din telefon"}'
         */
        heading: Localized<string>

        /**
         * @default '{"no": "Totalpris for telefonen etter {{rentalPeriod}} er {{totalPrice}}"}'
         */
        rentalPeriodTotalPrice: Localized<string>
    }

    stepsSummary: {
        /**
         * @default '{"en": "Summary", "no": "Oppsummering"}'
         */
        heading: Localized<string>

        tradeIn: {
            /**
             * @default '{"en": "We will deduct {{monthlyDiscount}} for the next {{discountPeriod}} months", "no": "Vi trekker fra {{monthlyDiscount}} på regningen din de neste {{discountPeriod}} månedene"}'
             */
            text: Localized<string>

            /**
             * @default '[{"id": "%UUID%", "text": {"no": "Du vil motta en bekreftelses-e-post med gratis fraktetikett."}}, {"id": "%UUID%", "text": {"no": "Vi kontrollerer mobilen og sier fra om ev. justeringer i verdi."}}, {"id": "%UUID%", "text": {"no": "Din månedlige kostnad reduseres allerede fra første betaling"}}]'
             */
            list?: {
                readonly id: Uuid
                /**
                 * @title
                 */
                text: Localized<string>
            }[]
        }

        shipping: {
            /**
             * @default '{"no": "Leveres {{deliveryDate}} mellom {{deliverySlotFrom}} og {{deliverySlotTo}}"}'
             */
            deliverySlotText: Localized<string>
        }
    }
}
export function PaymentStep(props: PaymentStepProps) {
    const context = useCheckoutContext()
    const [error, setError] = useState<string | undefined>()
    const localize = useLocalize()
    const [checkoutShippingState] = useCheckoutShippingState()
    const [checkoutTradeInPriceRequest] = useCheckoutTradeInPriceRequest()
    const [checkoutTradeInForm] = useCheckoutTradeInForm()
    const [checkoutTradeInResult] = useCheckoutTradeInResult()
    const [checkoutOrderId, setCheckoutOrderId] = useCheckoutOrderId()

    async function createOrder() {
        try {
            const order = await postPhoneOrder({
                phone: context.offer,
                shippingOption: Assert(checkoutShippingState.selectedSlot?.id),
                tradeIn: checkoutTradeInPriceRequest,
            })
            setCheckoutOrderId(order.id)
        } catch (e: any) {
            if ("detail" in e) {
                setError(e.detail)
            } else if ("message" in e) {
                setError(e.message)
            }
        }
    }
    const formatAmount = useCallback(
        (a: CurrencyAmount) => {
            return `${a.majorUnits} ${localize({ no: a.currency })}`
        },
        [localize]
    )

    useEffect(() => {
        dayjs.extend(advancedFormat)
    }, [])

    useEffect(() => {
        // Don't create a new order if already created one.
        if (!checkoutOrderId) {
            void createOrder()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(context.offer), checkoutOrderId])

    const selectedInsurance = useMemo(
        () =>
            context.insuranceOptions.data?.find(
                (insurance) => insurance.id === context.offer.insurance
            ),
        [context.insuranceOptions.data, context.offer.insurance]
    )

    return (
        <Flex direction="column" gap={32}>
            <Heading level={2}>{localize(props.heading)}</Heading>
            <Flex direction="column" gap={24}>
                <Heading level={3}>{localize(props.deviceSummary.heading)}</Heading>
                <CheckoutStepSummaryBlock
                    icon="device"
                    step={localize({ no: "Telefon" })}
                    title={localize(context.offer.name)}
                    price={
                        context.price?.monthlyCost.phone
                            ? `${formatAmount(context.price.monthlyCost.phone)}/${localize({ no: "md", en: "mo." })}`
                            : undefined
                    }
                    subtitle={MustacheString(localize(props.deviceSummary.rentalPeriodTotalPrice), {
                        rentalPeriod: `${context.offer.rentalPeriod.valueOf()} ${localize({ no: "md.", en: "mo." })}`,
                        totalPrice: context.price
                            ? `${formatAmount({ minorUnits: context.price.monthlyCost.phone.minorUnits * context.offer.rentalPeriod.valueOf(), majorUnits: context.price.monthlyCost.phone.majorUnits * context.offer.rentalPeriod.valueOf(), currency: context.price.monthlyCost.phone.currency })}`
                            : "",
                    })}
                />
            </Flex>
            <Flex direction="column" gap={24}>
                <Heading level={3}>{localize(props.stepsSummary.heading)}</Heading>
                {checkoutTradeInForm &&
                checkoutTradeInPriceRequest &&
                typeof checkoutTradeInResult?.value !== "undefined" &&
                context.price?.monthlyCost.tradeInDiscount &&
                checkoutTradeInResult.deviceName ? (
                    <CheckoutStepSummaryBlock
                        icon="handCoins"
                        step={localize(context.props.TradeIn.name)}
                        title={localize(checkoutTradeInResult.deviceName)}
                        subtitle={MustacheString(localize(props.stepsSummary.tradeIn.text), {
                            monthlyDiscount: formatAmount(checkoutTradeInResult.monthlyDiscount),
                            discountPeriod: `${checkoutTradeInResult.months.valueOf()}`,
                        })}
                        price={formatAmount(checkoutTradeInResult.value)}
                        list={props.stepsSummary.tradeIn.list?.map((li) => ({
                            id: li.id.valueOf(),
                            text: localize(li.text),
                        }))}
                    />
                ) : null}
                {selectedInsurance && context.price?.monthlyCost.insurance ? (
                    <CheckoutStepSummaryBlock
                        icon="shield"
                        step={localize(context.props.Insurance.name)}
                        title={localize(selectedInsurance.displayName)}
                        price={`${formatAmount(context.price.monthlyCost.insurance)}/${localize({ no: "md", en: "mo." })}`}
                        list={selectedInsurance.sellingPoints.map((isp) => ({
                            id: isp.id.valueOf(),
                            text: localize(isp.text),
                        }))}
                    />
                ) : null}
                {checkoutShippingState.selectedOption ? (
                    <CheckoutStepSummaryBlock
                        icon="truck"
                        step={localize(context.props.Shipping.name)}
                        title={localize(checkoutShippingState.selectedOption.name)}
                        subtitle={
                            checkoutShippingState.selectedSlot
                                ? MustacheString(
                                      localize(props.stepsSummary.shipping.deliverySlotText),

                                      {
                                          deliveryDate: dayjs(
                                              checkoutShippingState.selectedSlot.date
                                          ).format("dddd Do MMMM"),
                                          deliverySlotFrom: checkoutShippingState.selectedSlot.from,
                                          deliverySlotTo: checkoutShippingState.selectedSlot.to,
                                      }
                                  )
                                : undefined
                        }
                        price={
                            context.price && context.price.shippingCost.majorUnits.valueOf() > 0
                                ? formatAmount(context.price.shippingCost)
                                : localize({ no: "Gratis" })
                        }
                        text={[
                            checkoutShippingState.address?.street,
                            `${checkoutShippingState.address?.postalCode} ${checkoutShippingState.address?.region}`,
                        ]
                            .filter((t) => !!t)
                            .join(", ")}
                    />
                ) : null}
            </Flex>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <Box backgroundColor="gray100" borderRadius="md" padding="md">
                <Text variant="body" size="md" color="gray400">
                    <MarkdownView value={localize(props.paymentDetailsWillBeStoredText)} />
                </Text>
            </Box>
            {checkoutOrderId && (
                <AdyenComponent
                    onSuccess={() => context.setStep("Confirmation")}
                    adyenOrder="phone-subscription"
                    adyenOrderId={
                        // The Adyen Order ID is encoded as
                        // `${orderId}_${periodIndex}`. We are paying for the
                        // first period (0).
                        `${checkoutOrderId.valueOf()}_0`
                    }
                />
            )}
            {!checkoutOrderId && !error && <p>Reserving your order...</p>}
        </Flex>
    )
}

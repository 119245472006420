import { MouseEventHandler } from "react"
import { Component } from "../../../../../../packages/editing/Component"
import { colors } from "../../constants/colors"
import { css, resetStyles, responsiveCss } from "../../helpers/css"
import { Flex } from "../base/Flex"
import { Body } from "../typography/Body"
import { Text } from "../typography/Text"
import { Icon, IconName } from "../visual/Icon"

export function BoxSelection(props: {
    primary?: string
    secondary?:
        | string
        | {
              price: string
              currency: string
              period: string
          }

    text?: string
    list?: { id: string; icon?: IconName; text: string }[]
    link?:
        | {
              text: string
              url: string
          }
        | {
              text: string
              /**
               * @reflection any
               */
              onClick: MouseEventHandler<HTMLButtonElement>
          }
    selected?: boolean
    /**
     * @reflection any
     */
    style?: React.CSSProperties
    onClick?: () => void
}) {
    return (
        <Flex
            role="button"
            aria-label={props.primary}
            onClick={props.onClick}
            backgroundColor={props.selected ? "brandLight" : "grayWhite"}
            borderColor={props.selected ? "brand" : "gray200"}
            borderRadius="md"
            padding="md"
            style={{
                cursor: "pointer",
                transitionProperty: "background-color, border-color",
                transitionDuration: "0.30s",
                ...props.style,
            }}
        >
            <div
                style={{ display: "grid", width: "100%" }}
                css={css(
                    responsiveCss("max", "sm", { gap: 8 }),
                    responsiveCss("min", "md", { gridTemplateColumns: "0.56fr 1fr", gap: 40 })
                )}
            >
                <Flex
                    gap={8}
                    justifyContent="space-between"
                    css={css(
                        responsiveCss("max", "sm", { flexDirection: "column-reverse" }),
                        responsiveCss("min", "md", { flexDirection: "column" })
                    )}
                >
                    {typeof props.primary !== "undefined" && (
                        <Text variant="heading" level="3" color="brand">
                            {props.primary}
                        </Text>
                    )}
                    {typeof props.secondary !== "undefined" &&
                        (typeof props.secondary === "string" ? (
                            <Text variant="body" size="xl">
                                {props.secondary}
                            </Text>
                        ) : (
                            <span
                                css={css(
                                    responsiveCss("max", "sm", {
                                        color: colors.brand,
                                        fontWeight: 600,
                                    })
                                )}
                            >
                                <span css={css(responsiveCss("max", "sm", { display: "none" }))}>
                                    <Text as="span" variant="heading" level="3">
                                        {props.secondary.price} {props.secondary.currency}
                                    </Text>
                                    /{props.secondary.period}
                                </span>
                                <span css={css(responsiveCss("min", "md", { display: "none" }))}>
                                    <Text
                                        as="span"
                                        variant="heading"
                                        level="3"
                                        style={{ fontWeight: 600 }}
                                    >
                                        {props.secondary.price} {props.secondary.currency}{" "}
                                        {props.secondary.period}.
                                    </Text>
                                </span>
                            </span>
                        ))}
                </Flex>
                <Flex direction="column" justifyContent="space-between">
                    {typeof props.list !== "undefined" ? (
                        <ul css={resetStyles("ul")}>
                            {props.list.map((item) => (
                                <li
                                    key={item.id}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 8,
                                        marginBottom: 2,
                                    }}
                                >
                                    <Icon icon={item.icon ?? "check"} color="brand" />
                                    <Body size="md" color="gray400">
                                        {item.text}
                                    </Body>
                                </li>
                            ))}
                        </ul>
                    ) : null}
                    {typeof props.text !== "undefined" && (
                        <div
                            css={responsiveCss("min", "md", {
                                display: "flex",
                                alignItems: "center",
                                gap: 8,
                            })}
                        >
                            <Icon
                                icon="check"
                                css={responsiveCss("max", "sm", { display: "none" })}
                                style={{ opacity: 0 }}
                                aria-hidden={true}
                            />
                            <Body size="md" color="gray400">
                                {props.text}
                            </Body>
                        </div>
                    )}
                    {typeof props.link !== "undefined" && (
                        <Flex
                            justifyContent="flex-end"
                            css={css(
                                responsiveCss("max", "sm", { marginTop: 8 }),
                                responsiveCss("min", "md", { marginTop: 32 })
                            )}
                        >
                            {"onClick" in props.link ? (
                                <button
                                    onClick={props.link.onClick}
                                    css={css(resetStyles("button"))}
                                >
                                    <Text variant="body" size="sm" color="gray400" underline>
                                        {props.link.text}
                                    </Text>
                                </button>
                            ) : (
                                <a href={"url" in props.link ? props.link.url : undefined}>
                                    <Text variant="body" size="sm" color="gray400" underline>
                                        {props.link.text}
                                    </Text>
                                </a>
                            )}
                        </Flex>
                    )}
                </Flex>
            </div>
        </Flex>
    )
}

Component(BoxSelection, {
    name: "BoxSelection",
    gallery: {
        path: "Controllers/BoxSelection",
        items: [
            {
                title: "Selected",
                variants: [
                    {
                        props: {
                            primary: "Mobilforsikring fra Gjensidige",
                            secondary: "50 kr/md",
                            list: [
                                { id: "abc123", text: "Erstatning ved tyveri/tap mot egenandel." },
                                { id: "abc456", text: "Send mobilen inn gratis." },
                                { id: "abc789", text: "Motta direkte betaling til din konto." },
                            ],
                            link: { text: "Vilkår og betingelser", url: "#" },
                            selected: true,
                        },
                    },
                ],
            },
            {
                title: "Not selected",
                variants: [
                    {
                        props: {
                            secondary: "Ingen forsikringsdekning",
                            text: "Du er selv ansvarlig for verdien av mobilen, som er 10 000kr, hvis den blir skaded, mistet eller stjålet.",
                        },
                    },
                ],
            },
        ],
    },
})

import { MouseEventHandler, useCallback, useMemo, useState } from "react"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../../packages/localization/Localized"
import { BoxSelection } from "../../ui/components/controllers/BoxSelection"
import { Heading } from "../../ui/components/typography/Heading"
import { useCheckoutContext } from "./CheckoutContext"
import { Button } from "../../ui/components/buttons/Button"
import { Flex } from "../../ui/components/base/Flex"
import { css, responsiveCss } from "../../ui/helpers/css"
import { Markdown } from "../../../../../reactor"
import { Modal } from "../../ui/components/modal/Modal"
import { MarkdownView } from "../../../../../packages/markdown-edit/MarkdownView"
import { Text } from "../../ui/components/typography/Text"

export type InsuranceStepProps = {
    /**
     * Name of this step, used in checkout step navigation bar, and possibly other places the
     * step is referenced, like the summary page.
     *
     * @default '{"en": "Insurance", "no": "Forsikring"}'
     */
    name: Localized<string>

    /**
     * Insurance step identifier to use in the URL.
     * @default '{"en": "insurance", "no": "forsikring"}'
     */
    slug: Localized<string>

    /**
     * @default '{"en":"Insurance"}'
     */
    headline: Localized<string>

    /**
     * Text for the terms and condition link for each insurance.
     * @default '{"en": "Terms and conditions", "no": "Betingelser"}'
     */
    termsAndConditionsLinkText: Localized<string>

    noInsuranceHeader: Localized<string>
    noInsuranceText: Localized<string>

    primaryCta: Localized<string>
    notThisTimeButton: Localized<string>
}

export function InsuranceStep(props: InsuranceStepProps) {
    const localize = useLocalize()
    const { offer, setOffer, setStep, skipAuthStep, insuranceOptions } = useCheckoutContext()
    const selected = useMemo(
        () => insuranceOptions.data?.findIndex((io) => io.id === offer.insurance),
        [insuranceOptions.data, offer.insurance]
    )
    const [insuranceTermsMarkdown, setInsuranceTermsMarkdown] = useState<
        Localized<Markdown> | undefined
    >()
    const next = useCallback(() => {
        if (skipAuthStep) {
            setStep("Shipping")
        } else {
            setStep("Login")
        }
    }, [setStep, skipAuthStep])

    const handleTermsAndConditionsLinkClick = useCallback(
        (md: Localized<Markdown>): MouseEventHandler<HTMLButtonElement> =>
            (e) => {
                setInsuranceTermsMarkdown(md)
                e.preventDefault()
                e.stopPropagation()
            },
        []
    )

    if (insuranceOptions.loading) return <></>

    return (
        <Flex direction="column" gap={[16, ["min", "md", 32]]}>
            <Heading level={2}>{localize(props.headline)}</Heading>
            <Flex direction="column" gap={16}>
                {insuranceOptions.data?.map((option, i) => (
                    <BoxSelection
                        key={option.id.valueOf()}
                        onClick={() => setOffer({ ...offer, insurance: option.id })}
                        selected={selected === i}
                        primary={localize(option.displayName)}
                        secondary={{
                            price: option.pricePerMonth?.toString() ?? "",
                            currency: localize({ en: "NOK", no: "kr" }),
                            period: localize({ en: "month", no: "måned" }),
                        }}
                        list={option.sellingPoints.map((sp) => ({
                            id: sp.id.valueOf(),
                            icon: sp.icon,
                            text: localize(sp.text),
                        }))}
                        link={{
                            onClick: handleTermsAndConditionsLinkClick(option.termsAndConditions),
                            text: localize(props.termsAndConditionsLinkText),
                        }}
                    />
                ))}
                <BoxSelection
                    onClick={() => setOffer({ ...offer, insurance: undefined })}
                    selected={selected === -1}
                    secondary={localize(props.noInsuranceHeader)}
                    text={localize(props.noInsuranceText)}
                />
            </Flex>
            <Modal
                isOpen={!!insuranceTermsMarkdown}
                header={{ title: localize(props.termsAndConditionsLinkText), closeButton: true }}
                onClose={() => setInsuranceTermsMarkdown(undefined)}
            >
                <Text variant="body" size="sm">
                    {insuranceTermsMarkdown ? (
                        <MarkdownView value={localize(insuranceTermsMarkdown)} />
                    ) : null}
                </Text>
            </Modal>
            <Flex
                gap={8}
                css={css(
                    { flexWrap: "wrap-reverse" },
                    responsiveCss("max", "sm", { flexDirection: "column-reverse" })
                )}
            >
                <Button
                    css={css(responsiveCss("max", "sm", { display: "none" }))}
                    variant="secondary"
                    onClick={() => {
                        setOffer({ ...offer, insurance: undefined })
                        next()
                    }}
                >
                    {localize(props.notThisTimeButton)}
                </Button>
                <Button variant="primary" onClick={next}>
                    {localize(props.primaryCta)}
                </Button>
            </Flex>
        </Flex>
    )
}

import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../../packages/localization/Localized"
import { usePhoneOrder } from "../../client"
import { Heading } from "../../ui/components/typography/Heading"
import { useCheckoutOrderId } from "../Checkout"

export type ConfirmationStepProps = {
    /**
     * Name of this step, used in checkout step navigation bar, and possibly other places the
     * step is referenced, like the summary page.
     *
     * @default '{"en": "Order confirmation", "no": "Ordrebekreftelse"}'
     */
    name: Localized<string>

    headline: Localized<string>

    /**
     * Confirmation step identifier to use in the URL.
     * @default '{"en": "confirmation", "no": "bekreftelse"}'
     */
    slug: Localized<string>
}
export function ConfirmationStep(props: ConfirmationStepProps) {
    const [orderId] = useCheckoutOrderId()
    const order = usePhoneOrder(orderId ?? null)

    const localize = useLocalize()
    return (
        <div>
            <Heading level={2}>{localize(props.headline)}</Heading>
            {JSON.stringify(order.data)}
        </div>
    )
}

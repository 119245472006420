import {
    CSSProperties,
    ComponentPropsWithRef,
    ElementType,
    ForwardedRef,
    ReactNode,
    forwardRef,
} from "react"
import { CSSObject, SerializedStyles } from "@emotion/serialize"
import { css, underline } from "../../helpers/css"
import {
    HeadingSize,
    headingSizes,
    xlScaleFactor,
    ScreenSize,
    Size,
    FontWeight,
} from "../../constants/sizes"
import {
    FixedVariant,
    fixedVariantCss,
    responsiveCss,
    ResponsiveVariant,
    responsiveVariantsCss,
} from "../../helpers/css"
import { type Color, colors } from "../../constants/colors"
import { responsiveBodyCss } from "./Body"
import { Spacing, spacingToCss } from "../base/Box"

type TextVariant = "label" | "heading" | "body"

const textVariants: Partial<
    Record<TextVariant, Partial<Record<Size, Partial<Record<ScreenSize, CSSObject>>>>>
> = {
    label: {
        md: {
            xs: {
                fontSize: 12,
                fontWeight: 500,
                letterSpacing: 0.96,
                textTransform: "uppercase",
                lineHeight: "115%",
            },
            md: {
                fontSize: 12,
            },
            lg: {
                fontSize: 14,
                letterSpacing: 1.28,
            },
            xl: {
                fontSize: 16 * xlScaleFactor,
            },
        },
    },
}

/**
 * Utility type get the HTML element type from the HTML element name, e.g. HTMLDivElement from div.
 */
type HTMLElementType<T extends keyof JSX.IntrinsicElements> =
    JSX.IntrinsicElements[T] extends React.DetailedHTMLProps<React.HTMLAttributes<infer U>, infer U>
        ? U
        : never

type Element = "div" | "p" | "span" | "a" | "h1" | "h2" | "h3" | "h4" | "h5"

export type TextProps<T extends Element = "div"> = {
    /**
     * Specify which HTML element the Text should be rendered as.
     * By default it is rendered as a div.
     */
    as?: T

    /**
     * Specify a text color from the color palette.
     */
    color?: Color

    style?: CSSProperties

    lineHeight?: string | number
    fontSize?: number

    /**
     * Specify margin.
     */
    margin?: Spacing

    variant?: TextVariant

    level?: FixedVariant<HeadingSize> | ResponsiveVariant<HeadingSize>

    size?: FixedVariant<Size> | ResponsiveVariant<Size>

    fontWeight?: FontWeight

    /**
     * Add underline.
     */
    underline?: boolean

    /**
     * Maximum number of lines to render. Exceeding this number will render an ellipsis and hide
     * the overflowing lines.
     */
    lineClamp?: number

    /**
     * Truncate overflowing text. Forces nowrap and adds ellipsis.
     */
    truncate?: boolean

    /**
     * @reflection any
     */
    children?: ReactNode
} & ComponentPropsWithRef<T>

export const Text = forwardRef(function Text<T extends Element = "div">(
    props: TextProps<T>,
    ref: ForwardedRef<HTMLElementType<T>>
) {
    const El = props.as || ("div" as ElementType)

    return (
        <El
            ref={ref}
            style={{
                fontSize: props.fontSize,
                fontWeight: props.fontWeight,
                ...(props.style || {}),
            }}
            css={css(
                { "& a": underline },
                props.color && { color: colors[props.color] },
                props.lineHeight && { lineHeight: props.lineHeight },
                !!props.variant &&
                    (props.variant === "heading" && props.level
                        ? responsiveHeadingCss(props.level)
                        : props.variant === "body" && props.size
                          ? responsiveBodyCss(props.size)
                          : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            props.variant && props.size
                            ? textCss(props.variant, props.size)
                            : undefined),

                typeof props.lineClamp !== "undefined" && {
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: `${props.lineClamp}`,
                    lineClamp: `${props.lineClamp}`,
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                },
                props.truncate && {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                },
                props.margin && spacingToCss("margin", props.margin),
                props.underline && underline
            )}
            className={props.className}
        >
            {props.children}
        </El>
    )
})

function textCss(
    variant: keyof typeof textVariants,
    size: ResponsiveVariant<Size> | FixedVariant<Size>
) {
    const textVariant = textVariants[variant]
    if (!textVariant) return
    return size instanceof Array && size[0] === "fixed"
        ? fixedVariantCss(textVariant, size)
        : responsiveVariantsCss(textVariant, size)
}

export function responsiveHeadingCss(
    size: ResponsiveVariant<HeadingSize> | FixedVariant<HeadingSize>
): SerializedStyles | undefined {
    if (size instanceof Array && size[0] === "fixed") {
        return fixedVariantCss(headingSizes, size)
    }
    return responsiveVariantsCss(headingSizes, size)
}

/**
 * Helper function that returns a media query with responsive CSS for a given body size, based
 * on sizes defined in the `bodySizes` constant.
 *
 * @param variant The border radius variant
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function responsiveTextVariant(variant: TextVariant) {
    if (typeof textVariants[variant] === "object") {
        return Object.entries(textVariants[variant] as Record<ScreenSize, CSSProperties>).map(
            ([screenSize, bodyStyle]) =>
                responsiveCss("min", screenSize as ScreenSize, { ...bodyStyle })
        )
    }

    return []
}

// TODO: Implement support for p3 variants

/**
 * The colors in Redoit design system
 *
 * Do not shorten to less than 6 chars, to ensure setting opacity using 2 char hex.
 */
export const colors = {
    // Primary
    brand: "#9b64e1",
    brandLight: "#f4e3ff",
    brandDark: "#7248a8",

    // Grays
    grayWhite: "#ffffff",
    gray100: "#f6f6f6",
    gray200: "#e9e6ea",
    gray300: "#868589",
    // For gray text on gray100 background, use this to satisfy WCAG AA requirements.
    gray350: "#717075",
    gray400: "#4d4852",
    gray500: "#150624",

    // Secondary
    forest: "#155c58",
    forestLight: "#e9ede9",
    sky: "#347cff",
    skyLight: "#e5e3ff",
    sand: "#b49469",
    sandLight: "#f7f2ef",
    highlight: "#c5ffaa",
    error: "#ff4c34",
    warning: "#ff4c34",
    warningLight: "#ffedeb",
    vippsOrange: "#ff5b24",

    // Colors used in specific components that are not defined in the design system
    _productsSeeAllBg: "#efeae7",
    _trustpilot: "#63d793",
} as const

export type Color = keyof typeof colors

import { BrowserRouter } from "react-router-dom"
import "../../../packages/ssr/SSR2"
import { Login } from "../../../packages/ssr/Login"
import { defaults } from "./client"
import { defaults as studioDefaults } from "../../../studio/client"
import { InitWebApp } from "../../../packages/ssr/InitWebApp"
import { App } from "./App"
import "./Studio"
import { loginWithVipps } from "../../../packages/oidc/code-flow/VippsLogin"

InitWebApp(() => (
    <Login
        always={false}
        tokenReady={(token) => {
            defaults.headers.Authorization = `Bearer ${token}`
            studioDefaults.headers.Authorization = `Bearer ${token}`
        }}
        clearTokens={() => {
            defaults.headers.Authorization = ""
            studioDefaults.headers.Authorization = ""
        }}
        login={async (defaultLogin, returnTo, error) => {
            if (error?.type === "UnauthorizedWithVipps") {
                if (await loginWithVipps(returnTo ?? "/")) return
                else window.history.back()
            } else {
                defaultLogin(returnTo)
            }
        }}
    >
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Login>
))

import {
    createRef,
    Fragment,
    ReactNode,
    RefObject,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../../packages/localization/Localized"
import { Flex } from "../../ui/components/base/Flex"
import { Button } from "../../ui/components/buttons/Button"
import { Modal, ModalContext } from "../../ui/components/modal/Modal"
import { Heading } from "../../ui/components/typography/Heading"
import { Text } from "../../ui/components/typography/Text"
import { Icon, IconName } from "../../ui/components/visual/Icon"
import { css, responsiveBorderRadius, responsiveCss, scaleValue } from "../../ui/helpers/css"
import { useCheckoutContext } from "./CheckoutContext"
import {
    GetTradeInFormDto,
    GetTradeInFormQuestionsResponsesDto,
    postTradeInFormsValue,
} from "../../client"
import { Uuid } from "../../../../../reactor/Types/Primitives/Uuid"
import { Assert, ImageToUrl, Markdown } from "../../../../../reactor"
import { Select } from "../../ui/components/inputs/Select"
import { Body } from "../../ui/components/typography/Body"
import { Divider } from "../../ui/components/other/Divider"
import { motion, MotionConfig } from "framer-motion"
import { springAnimations } from "../../ui/constants/animation"
import { MultipleChoiceQuestion } from "../../ui/components/forms/MultipleChoiceQuestion"
import { PaymentSummary } from "../../ui/components/forms/PaymentSummary"
import { MustacheString } from "../../../../../packages/editing/Mustache"
import { Box } from "../../ui/components/base/Box"
import { fixedSpacingSizes } from "../../ui/constants/sizes"
import { Checkbox } from "../../ui/components/controllers/Checkbox"
import { useSessionState } from "../../../../../reactor/Web"
import {
    useCheckoutTradeInForm,
    useCheckoutTradeInPriceRequest,
    useCheckoutTradeInResult,
} from "../Checkout"

export type TradeInStepProps = {
    /**
     * Name of this step, used in checkout step navigation bar, and possibly other places the
     * step is referenced, like the summary page.
     *
     * @default '{"en": "Trade-in", "no": "Innbytte"}'
     */
    name: Localized<string>

    /**
     * Trade-in step identifier to use in the URL.
     * @default '{"en": "trade-in", "no": "innbytte"}'
     */
    slug: Localized<string>

    /**
     * @default '{"en": "Trade in your old device"}'
     */
    headline: Localized<string>

    /**
     * @default '{"en": "How does it work?"}'
     */
    leadText: Localized<string>

    /**
     * @expand
     * @default '[{"id": "%UUID%", "text": {"en": "Get a quote for your old device"}}, {"id": "%UUID%", "text": {"en": "Send in your old device"}}, {"id": "%UUID%", "text": {"en": "Get paid"}}]'
     */
    steps: {
        readonly id: Uuid
        /**
         * @title
         */
        text: Localized<string>
    }[]

    /**
     * Text for button that starts the trade-in flow.
     * @default '{"no": "Få umiddelbar verdivurdering"}'
     */
    primaryCta: Localized<string>

    /**
     * Text for the button to press for no trade-in.
     * @default '{"no": "Ikke denne gangen, takk"}'
     */
    notThisTimeButton: Localized<string>

    modal: {
        questions: {
            /**
             * @default '{"en": "Trade in your old device"}'
             */
            heading: Localized<string>

            /**
             * @default '{"en": "Continue", "no": "Fortsett"}'
             */
            continueButtonText: Localized<string>
        }

        summary: {
            /**
             * @default '{"en": "Summary", "no": "Oppsummering"}'
             */
            heading: Localized<string>

            /**
             * @default '{"en": "After trade-in", "no": "Etter innbytte"}'
             */
            priceSummaryLabel: Localized<string>

            /**
             * @default '{"en": "Your estimated phone value is {{itemValue}} {{currency}}. This means you will get {{discountAmount}} {{currency}}/mo off your contract for the next {{discountMonths}} months after trading in your device."}'
             */
            priceSummaryText: Localized<string>

            /**
             * @default '{"en": "Trade-in summary", "no": "Innbyttesammendrag"}'
             */
            answersSummaryHeading: Localized<string>

            /**
             * @default '{"en": "Continue", "no": "Fortsett"}'
             */
            continueButtonText: Localized<string>
        }

        confirmation: {
            /**
             * @default '{"en": "Next steps", "no": "Hva skjer videre?"}'
             */
            nextStepsHeading: Localized<string>

            /**
             * @expand
             * @default '[{"id": "%UUID%", "icon": "at", "text": {"en": "You will receive a confirmation email with the free shipping label."}}, {"id": "%UUID%", "icon": "delivery", "text": {"en": "Send us the mobile phone at no cost to you."}}]'
             */
            nextSteps: {
                readonly id: Uuid
                icon: IconName
                /**
                 * @title
                 */
                text: Localized<string>
            }[]

            /**
             * @expand
             * @default '[{"id": "%UUID%", "text": {"en": "I have removed the device from the \"Find My iPhone\" feature. [How to do it](https://www.redoit.no)"}}, {"id": "%UUID%", "text": {"en": "I certify I haven’t lied about the condition of my phone and all the information provided is accurate."}}]'
             */
            acknowledgements: {
                readonly id: Uuid
                /**
                 * @title
                 */
                text: Localized<Markdown>
            }[]

            /**
             * @default '{"en": "Complete and trade-in my device"}'
             */
            continueButtonText: Localized<string>
        }

        closePrompt?: {
            /**
             * @default '{"en": "Are you sure you want to cancel trade-in?"}'
             */
            heading: Localized<string>

            /**
             * @default '{"en": "By trading in you’ll get a monthly discount and contribute to a more sustainable future by supporting the circular economy and reducing environmental impact."}'
             */
            text: Localized<string>

            /**
             * @default '{"en": "No, continue trade-in"}'
             */
            cancelButtonText: Localized<string>

            /**
             * @default '{"en": "Yes, cancel trade-in"}'
             */
            confirmButtonText: Localized<string>
        }
    }
}
export function TradeInStep(props: TradeInStepProps) {
    const localize = useLocalize()
    const { setStep } = useCheckoutContext()
    const [modalOpen, setModalOpen] = useSessionState("tradein-modal-open", false)

    function startTradeIn() {
        setModalOpen(true)
    }

    return (
        <Flex
            direction="column"
            justifyContent="center"
            gap={scaleValue(32)}
            css={css({ height: "100%" })}
        >
            <Heading level={2}>{localize(props.headline)}</Heading>
            <Flex direction="column" gap={20}>
                <Text variant="body" size="xl">
                    {localize(props.leadText)}
                </Text>
                <Flex direction="column" gap={[4, ["min", "md", 8]]}>
                    {props.steps.map((item, i) => (
                        <Flex key={item.id.valueOf()} gap={8} alignItems="center">
                            <Icon icon="check" color="forest" />
                            <Text variant="body" color="gray300" size="md">
                                {localize(item.text)}
                            </Text>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
            <Flex
                gap={8}
                css={css(
                    { flexWrap: "wrap-reverse", justifyContent: "stretch" },
                    responsiveCss("max", "sm", {
                        flexDirection: "column-reverse",
                    })
                )}
            >
                <Button variant="secondary" onClick={() => setStep("Insurance")}>
                    {localize(props.notThisTimeButton)}
                </Button>
                <Button variant="primary" onClick={startTradeIn}>
                    {localize(props.primaryCta)}
                </Button>
            </Flex>
            <TradeInModal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false)
                }}
                {...props}
            />
        </Flex>
    )
}

function TradeInModal(props: TradeInStepProps & { open: boolean; onClose: () => void }) {
    const context = useCheckoutContext()
    const [form] = useCheckoutTradeInForm()
    const localize = useLocalize()
    const [responses, setResponses] = useSessionState<(Uuid<"TradeInResponse"> | undefined)[]>(
        "tradein-responses",
        []
    )
    const [result, setResult] = useCheckoutTradeInResult()
    const [, setCheckoutTradeInPriceRequest] = useCheckoutTradeInPriceRequest()

    const answerImage = useMemo(() => {
        if (!form) return undefined
        const rr = [...responses].reverse()
        const qr = [...form.questions].reverse()

        return qr.reduce((acc: GetTradeInFormQuestionsResponsesDto | undefined, curr, index) => {
            // Question hasn't been answered yet, don't check for image.
            if (!rr[index]) return undefined

            // We started from the last question, so if already set, keep it.
            if (acc) return acc

            // Return if selected response for question has an image
            return curr.responses.find((r) => r.id === rr[index] && r.image)
        }, undefined)
    }, [responses, form])

    const PaymentSummaryRendered = useMemo(
        () =>
            result && form ? (
                <PaymentSummary
                    itemName={localize(context.offer.name)}
                    text={MustacheString(localize(props.modal.summary.priceSummaryText), {
                        currency: result.value.currency.valueOf(),
                        itemValue: result.value.majorUnits.toString(),
                        discountAmount: result.monthlyDiscount.majorUnits.toString(),
                        discountMonths: result.months.toString(),
                    })}
                    originalPrice={context.offer.monthlyPrice.total.majorUnits}
                    discountedPrice={
                        context.offer.monthlyPrice.total.majorUnits -
                        result.monthlyDiscount.majorUnits
                    }
                    rentalPeriodUnit={localize({ en: "mo", no: "md" })}
                    currency={result.value.currency}
                    label={localize(props.modal.summary.priceSummaryLabel)}
                />
            ) : null,
        [
            result,
            form,
            localize,
            context.offer.name,
            context.offer.monthlyPrice.total.majorUnits,
            props.modal.summary.priceSummaryText,
            props.modal.summary.priceSummaryLabel,
        ]
    )

    useEffect(() => {
        if (form && responses.length !== form.questions.length) {
            setResponses(form.questions.map(() => undefined))
        }
    }, [form, form?.questions.length, responses.length, setResponses])

    const [currentStep, setCurrentStep] = useSessionState<number | undefined>(
        "tradein-modal-step",
        undefined
    )
    if (!form) {
        return <></>
    }

    return (
        <Modal
            isOpen={props.open}
            onClose={() => {
                setResponses([])
                setCurrentStep(undefined)
                props.onClose()
            }}
            width="100%"
            onCloseAnimationEnd={() => {
                context.setStep("Insurance")
            }}
            header={{
                closeButton: true,
            }}
            closePrompt={
                props.modal.closePrompt
                    ? {
                          heading: localize(props.modal.closePrompt.heading),
                          text: localize(props.modal.closePrompt.text),
                          cancelButtonText: localize(props.modal.closePrompt.cancelButtonText),
                          confirmButtonText: localize(props.modal.closePrompt.confirmButtonText),
                      }
                    : undefined
            }
            step={currentStep}
            onStepChange={(val) => setCurrentStep(val)}
            steps={[
                {
                    header: { title: localize(props.modal.questions.heading) },
                    render: (next, prev) => (
                        <QuestionsStep
                            {...props}
                            form={form}
                            responsesState={[responses, setResponses]}
                            onSubmitAnswers={async () => {
                                try {
                                    const response = await postTradeInFormsValue("Phones", {
                                        months: context.offer.rentalPeriod,
                                        responses: responses.map((r) => Assert(r)),
                                    })
                                    setResult(response)
                                    next()
                                } catch (e: any) {
                                    if ("detail" in e) alert(e.detail)
                                    else alert("An error occurred")
                                }
                            }}
                        />
                    ),
                },
                {
                    header: {
                        backButton: true,
                        title: localize(props.modal.summary.heading),
                    },
                    render: (next, prev) => (
                        <SummaryStep
                            {...props}
                            form={form}
                            responsesState={[responses, setResponses]}
                            PaymentSummaryRendered={PaymentSummaryRendered}
                            onNextClick={next}
                        />
                    ),
                },
                {
                    header: { backButton: true },
                    render: (next, prev) => (
                        <ConfirmationStep
                            {...props}
                            form={form}
                            PaymentSummaryRendered={PaymentSummaryRendered}
                            onTradeInConfirm={() => {
                                setCheckoutTradeInPriceRequest({
                                    form: form.id,
                                    responses: responses.map((r) => Assert(r)),
                                    version: form.version,
                                })
                                props.onClose()
                            }}
                        />
                    ),
                },
            ]}
            renderSteps={(step) => (
                <MotionConfig transition={springAnimations["200"]}>
                    <div style={{ display: "flex", gap: 20, width: "100%" }}>
                        <div css={css({ display: "flex", flex: "5 0 0", position: "relative" })}>
                            {step}
                        </div>
                        {form.image ? (
                            <div
                                css={css(
                                    { flex: "2 0 0" },
                                    responsiveCss("max", "sm", { display: "none" }),
                                    responsiveCss("min", "md", { flexGrow: 3 }),
                                    responsiveCss("min", "lg", { flexGrow: 4 })
                                )}
                            >
                                <div style={{ position: "sticky", top: 0, right: 0 }}>
                                    <div
                                        style={{
                                            position: "relative",
                                            overflow: "hidden",
                                            aspectRatio: "1/1",
                                        }}
                                        css={responsiveBorderRadius("md")}
                                    >
                                        {answerImage?.image && (
                                            <Flex
                                                motion={{
                                                    initial: { opacity: 0 },
                                                    animate: { opacity: 1 },
                                                }}
                                                alignItems="center"
                                                justifyContent="center"
                                                backgroundColor="gray100"
                                                style={{
                                                    position: "absolute",
                                                    height: "100%",
                                                    width: "100%",
                                                }}
                                            >
                                                <img
                                                    src={answerImage.image.valueOf()}
                                                    style={{ width: "50%" }}
                                                />
                                            </Flex>
                                        )}
                                        <motion.div
                                            animate={answerImage?.image ? { opacity: 0 } : {}}
                                        >
                                            <img
                                                src={ImageToUrl(form.image)}
                                                style={{
                                                    objectFit: "cover",
                                                    width: "100%",
                                                    aspectRatio: "1/1",
                                                }}
                                            />
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </MotionConfig>
            )}
        ></Modal>
    )
}

function QuestionsStep(
    props: TradeInStepProps & {
        form: GetTradeInFormDto
        responsesState: [
            (Uuid<"TradeInResponse"> | undefined)[],
            (newState: (Uuid<"TradeInResponse"> | undefined)[]) => void,
        ]
        onSubmitAnswers: () => void
    }
) {
    const localize = useLocalize()
    const questionRefs = useRef<RefObject<HTMLDivElement>[]>([])
    const [responses, setResponses] = props.responsesState

    useEffect(() => {
        questionRefs.current = props.form.questions.map(() => createRef())
    }, [props.form.questions])

    const modalContext = useContext(ModalContext)

    return (
        <>
            {props.form.questions.map((q, qi) => (
                <motion.div
                    key={q.id.valueOf()}
                    style={{ overflow: "hidden" }}
                    initial={
                        qi > 0 && responses.filter((r) => r).length < qi
                            ? { height: 0, opacity: 0, translateY: -20 }
                            : { height: "auto" }
                    }
                    animate={
                        qi > 0 && responses.filter((r) => r).length >= qi
                            ? {
                                  height: questionRefs.current[qi]?.current?.getBoundingClientRect()
                                      .height,
                                  opacity: 1,
                                  translateY: 0,
                              }
                            : qi > 0
                              ? {
                                    height: 0,
                                    opacity: 0,
                                    translateY: -20,
                                }
                              : {}
                    }
                    onAnimationComplete={() => {
                        const scrollContainer = modalContext.scrollContainerRef?.current
                        const rect = questionRefs.current[qi]?.current?.getBoundingClientRect()
                        if (scrollContainer && rect) {
                            scrollContainer.scroll({
                                top: scrollContainer.scrollTop + rect.height,
                                behavior: "smooth",
                            })
                        }
                    }}
                >
                    <div ref={questionRefs.current[qi]}>
                        {q.type === "buttons" ? (
                            <MultipleChoiceQuestion
                                question={localize(q.question)}
                                description={q.description ? localize(q.description) : undefined}
                                options={q.responses.map((r) => ({
                                    value: r.id,
                                    text: localize(r.response),
                                }))}
                                value={responses[qi]}
                                onChange={(e, val) => {
                                    setResponses(responses.map((r, i) => (i === qi ? val : r)))
                                }}
                            />
                        ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        q.type === "dropdown" ? (
                            <div>
                                <Box margin={{ bottom: scaleValue(16) }}>
                                    <Body size="md" margin={{ bottom: 16 }}>
                                        {localize(q.question)}
                                    </Body>
                                    {q.description ? (
                                        <Text variant="body" size="sm" color="gray300">
                                            {localize(q.description)}
                                        </Text>
                                    ) : null}
                                </Box>
                                <Select
                                    value={responses[qi]}
                                    placeholder={localize({
                                        en: "Please select",
                                        no: "Vennligst velg",
                                    })}
                                    onChange={(e, val) => {
                                        if (qi === 0) {
                                            setResponses(
                                                responses.map((r, ri) =>
                                                    ri === 0 ? (val as any) : undefined
                                                )
                                            )
                                        } else {
                                            setResponses(
                                                responses.map((r, i) => (i === qi ? val : r))
                                            )
                                        }
                                    }}
                                    options={[
                                        ...q.responses.map((r) => ({
                                            value: r.id,
                                            text: localize(r.response),
                                        })),
                                    ]}
                                    renderValue={(val) =>
                                        localize(
                                            q.responses.find((x) => x.id === val)?.response ?? {}
                                        )
                                    }
                                />
                            </div>
                        ) : (
                            <div>Unsupported question type: {q.type}</div>
                        )}
                        <Divider horizontal spacing={32} />
                    </div>
                </motion.div>
            ))}
            <Button
                variant="primary"
                onClick={() => {
                    try {
                        props.onSubmitAnswers()
                    } catch (err) {}
                }}
                iconEnd="arrowRight"
                disabled={responses.some((r) => !r)}
                fullwidth
            >
                {localize({ en: "Continue", no: "Fortsett" })}
            </Button>
        </>
    )
}

function SummaryStep(
    props: TradeInStepProps & {
        PaymentSummaryRendered: ReactNode
        form: GetTradeInFormDto
        responsesState: [
            (Uuid<"TradeInResponse"> | undefined)[],
            (newState: (Uuid<"TradeInResponse"> | undefined)[]) => void,
        ]
        onNextClick: () => void
    }
) {
    const localize = useLocalize()
    const [responses] = props.responsesState

    return (
        <>
            <Flex gap={40}>
                <div css={css({ flex: "5 0 0" })}>
                    {props.PaymentSummaryRendered}
                    <Heading
                        level={3}
                        margin={{
                            top: fixedSpacingSizes.lg,
                            bottom: fixedSpacingSizes.sm,
                        }}
                    >
                        {localize(props.modal.summary.answersSummaryHeading)}
                    </Heading>
                    <Box
                        borderColor="gray200"
                        borderRadius="md"
                        padding="md"
                        margin={{ bottom: fixedSpacingSizes.lg }}
                    >
                        {props.form.questions.map((q, qi) => (
                            <Fragment key={q.id.valueOf()}>
                                {qi > 0 ? <Divider horizontal spacing="md" /> : null}
                                <Text variant="body" size="sm">
                                    {localize(q.question)}
                                </Text>
                                <Text variant="heading" level="4">
                                    {localize(
                                        q.responses.find((r) => r.id === responses[qi])!.response
                                    )}
                                </Text>
                            </Fragment>
                        ))}
                    </Box>
                    <Button iconEnd="arrowRight" onClick={props.onNextClick} fullwidth>
                        {localize(props.modal.summary.continueButtonText)}
                    </Button>
                </div>
            </Flex>
        </>
    )
}

function ConfirmationStep(
    props: TradeInStepProps & {
        form: GetTradeInFormDto
        PaymentSummaryRendered: ReactNode
        onTradeInConfirm: () => void
    }
) {
    const localize = useLocalize()
    const [checkedAcknowledgements, setCheckedAcknowledgements] = useState<Uuid[]>([])

    return (
        <>
            <Flex direction="column" gap={fixedSpacingSizes.lg}>
                {props.PaymentSummaryRendered}
                <div>
                    <Heading level={3} margin={{ bottom: fixedSpacingSizes.sm }}>
                        {localize(props.modal.confirmation.nextStepsHeading)}
                    </Heading>
                    <Flex direction="column" gap={12}>
                        {props.modal.confirmation.nextSteps.map((ns) => (
                            <Flex
                                key={ns.id.valueOf()}
                                gap={fixedSpacingSizes.sm}
                                backgroundColor="gray100"
                                padding="md"
                                borderRadius="md"
                                alignItems="center"
                            >
                                <Icon icon={ns.icon} size={32} />
                                <Text variant="body" size="md">
                                    {localize(ns.text)}
                                </Text>
                            </Flex>
                        ))}
                    </Flex>
                </div>
                <div>
                    {props.modal.confirmation.acknowledgements.map((a, ai) => (
                        <Fragment key={a.id.valueOf()}>
                            {ai > 0 ? <Divider horizontal spacing={fixedSpacingSizes.md} /> : null}
                            <Checkbox
                                label={localize(a.text)}
                                checked={checkedAcknowledgements.includes(a.id)}
                                onChange={() =>
                                    setCheckedAcknowledgements(
                                        checkedAcknowledgements.includes(a.id)
                                            ? checkedAcknowledgements.filter((ca) => ca !== a.id)
                                            : [...checkedAcknowledgements, a.id]
                                    )
                                }
                            />
                        </Fragment>
                    ))}
                </div>
                <Button
                    fullwidth
                    disabled={
                        checkedAcknowledgements.length <
                        props.modal.confirmation.acknowledgements.length
                    }
                    variant="primary"
                    onClick={props.onTradeInConfirm}
                >
                    {localize(props.modal.confirmation.continueButtonText)}
                </Button>
            </Flex>
        </>
    )
}

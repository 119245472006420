import { useCallback } from "react"
import { server } from "../../../server"

let loggingInWithVipps = false
/**
 * Start the login process with Vipps.
 *
 * If login starts, the browser will be redirected to the Vipps login page and
 * this function will never actually return.
 *
 * Returns true if login was already started.
 *
 * Returns false if the login could not be started. You should typically handle
 * this by navigating back to the previous page, to avoid being stuck
 */
export async function loginWithVipps(redirectUrl: string, serviceName = "vipps"): Promise<boolean> {
    // Prevent multiple login attempts at the same time
    if (loggingInWithVipps) {
        return true
    }
    loggingInWithVipps = true

    try {
        const res = await fetch(
            `${server()}/api/${serviceName}/auth/url?redirectUrl=${encodeURIComponent(redirectUrl)}`
        )
        if (res.status === 200) {
            const json = await res.json()
            window.location.href = json.authUrl
        } else {
            const error = await res.json()
            alert("Failed to start Vipps login: " + error.detail)
            return false
        }
    } catch (e: any) {
        alert(e.message ?? e.detail)
    } finally {
        loggingInWithVipps = false
    }

    // Should be unreachable, as the browser will be redirected to the Vipps login page in
    // the success case above
    throw new Error("Unreachable code reached in loginWithVipps")
}

export function useLogInWithVipps(
    redirectUrl: string,
    /** In case of multiple vipps service instances, this can be used to disambiguate. */
    serviceName = "vipps"
) {
    return useCallback(
        async () => loginWithVipps(redirectUrl, serviceName),
        [redirectUrl, serviceName]
    )
}

export function useLogOutWithVipps(
    /** In case of multiple vipps service instances, this can be used to disambiguate. */
    serviceName = "vipps"
) {
    return useCallback(async () => {
        try {
            const url = `${server()}/api/${serviceName}/auth/session`
            await fetch(url, { method: "DELETE" })

            window.location.reload()
        } catch (e: any) {
            alert(e.message ?? e.detail)
        }
    }, [serviceName])
}

import { useState } from "react"
import { MotionConfig, motion } from "framer-motion"
import { Component } from "../../../../../../packages/editing/Component"
import { Image } from "../../../../../../reactor"
import { server } from "../../../../../../server"
import { Flex } from "../base/Flex"
import { ResponsiveImage } from "../base/ResponsiveImage"
import { Button } from "../buttons/Button"
import { Color, colors } from "../../constants/colors"
import { componentAnimations } from "../../constants/animation"
import { Text } from "../typography/Text"
import {
    responsiveBodySize,
    responsiveBoxShadowVars,
    responsiveCss,
    responsiveSpacing,
    touchCss,
    css,
} from "../../helpers/css"
import { LabelNotification } from "../notification/LabelNotification"
import { xlScaleFactor } from "../../constants/sizes"
import { Icon } from "../visual/Icon"

/**
 * Renders a product card to be used in a grid (on desktop) or list (on mobile). Rather than doing
 * this entirely responsive, the variant to render is controlled by a the `compact` prop, so that
 * both variants can be rendered on any screen size, i.e. also in gallery for example.
 */
export function ProductCard(props: {
    name: string
    features: string[]
    price: string

    /**
     * Highlight the price on this card.
     */
    highlightPrice?: boolean

    /**
     * An image of the product.
     */
    image?: Image

    /**
     * Show a label to bring more attention to the product, e.g. "new" or "deal"
     */
    label?: {
        text: string
        color: Color
    }

    /**
     * Call to action button text and URL. For compact version, no button will be rendered, and
     * clicking the card will open the provided URL.
     *
     * @label Call to action
     */
    cta: { text: string; url: string }

    /**
     * Show the compact version for use in a list.
     */
    compact?: boolean

    /**
     * Always show, the CTA button, not just on mouseover.
     */
    alwaysShowCta?: boolean

    /**
     * Reduce the size of the product to this relative physical size where 100 is full size.
     */
    relativePhysicalSize?: number

    /**
     * Callback for when the card is clicked. If provided, the cta link will not
     * be followed.
     */
    onClick?: () => void
}) {
    const [hover, setHover] = useState(false)

    const href = props.onClick ? undefined : props.cta.url

    if (props.compact) {
        return (
            <Flex
                backgroundColor="gray100"
                padding="sm"
                borderRadius="md"
                grow={1}
                css={css({
                    position: "relative",
                    "&:hover": {
                        cursor: "pointer",
                    },
                })}
            >
                <Flex as="a" grow={1} href={href} onClick={props.onClick}>
                    <Flex
                        css={css({ width: 96, height: 112, marginRight: 24 })}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {props.image ? (
                            <ResponsiveImage
                                image={props.image}
                                width={240}
                                style={{
                                    flex: "0 0 0",
                                    height: "auto",
                                    width: "auto",
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                }}
                            />
                        ) : null}
                    </Flex>
                    <Flex
                        direction="column"
                        justifyContent="space-between"
                        grow={1}
                        style={{ paddingTop: 12, paddingBottom: 12 }}
                    >
                        <div>
                            {props.label ? (
                                <Text variant="label" size="md" color={props.label.color}>
                                    {props.label.text}
                                </Text>
                            ) : null}
                            <Text variant="body" size="xl">
                                {props.name}
                            </Text>
                            <Text variant="body" size="sm" color="gray350">
                                <Flex gap={4}>
                                    {props.features.slice(0, 2).map((f, i) => (
                                        <>
                                            {i > 0 ? <div>·</div> : null}
                                            <div>{f}</div>
                                        </>
                                    ))}
                                </Flex>
                            </Text>
                        </div>
                        <Text
                            variant="body"
                            size="xl"
                            fontWeight={600}
                            color={props.highlightPrice ? "brand" : undefined}
                        >
                            {props.price}
                        </Text>
                    </Flex>
                </Flex>
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="grayWhite"
                    style={{
                        position: "absolute",
                        bottom: 16,
                        right: 16,
                        borderRadius: "100vw",
                        width: 40,
                        height: 40,
                        pointerEvents: "none",
                    }}
                >
                    <Icon icon="arrowRight" />
                </Flex>
            </Flex>
        )
    }

    return (
        <MotionConfig transition={componentAnimations.card}>
            <Flex
                as="a"
                href={href}
                onClick={props.onClick}
                role="button"
                aria-labelledby={props.cta.text}
                motion={{
                    animate: hover
                        ? {
                              backgroundColor: colors.grayWhite,
                              boxShadow: "var(--product-card-box-shadow)",
                          }
                        : { backgroundColor: colors.gray100, boxShadow: "none" },
                }}
                borderRadius="md"
                alignItems="center"
                justifyContent="space-between"
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                css={css(
                    {
                        position: "relative",
                        containerSize: "size",
                        containerName: "card",
                        "&:hover": {
                            cursor: "pointer",
                        },
                        height: "100%",
                        flexDirection: "column",
                        paddingTop: 50,
                        paddingBottom: 30,
                    },
                    responsiveCss("min", "lg", {
                        paddingBottom: 38,
                    }),
                    responsiveCss("min", "xl", {
                        paddingTop: 64 * xlScaleFactor,
                        paddingBottom: 48 * xlScaleFactor,
                    }),
                    responsiveSpacing("md", ["paddingLeft", "paddingRight"]),
                    responsiveBoxShadowVars("--product-card-box-shadow")
                )}
            >
                <div
                    css={css(
                        { position: "absolute", top: 19, right: 19 },
                        responsiveCss("min", "xl", {
                            top: 24 * xlScaleFactor,
                            right: 24 * xlScaleFactor,
                        })
                    )}
                >
                    {props.label ? (
                        <LabelNotification color={props.label.color}>
                            {props.label.text}
                        </LabelNotification>
                    ) : null}
                </div>
                <Flex direction="column" style={{ maxWidth: "100%" }}>
                    <motion.div
                        css={css(
                            {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                height: 192,
                                marginBottom: 30,
                                transform: props.alwaysShowCta
                                    ? "translateY(var(--product-card-image-translate-y))"
                                    : "",
                                "--product-card-image-translate-y": `${8 * xlScaleFactor * xlScaleFactor}px`,
                            },
                            responsiveCss("min", "lg", {
                                height: 220,
                                marginBottom: 38,
                                "--product-card-image-translate-y": `${8 * xlScaleFactor}px`,
                            }),
                            responsiveCss("min", "xl", {
                                height: 284 * xlScaleFactor,
                                marginBottom: 48 * xlScaleFactor,
                                "--product-card-image-translate-y": `${8}px`,
                            })
                        )}
                        animate={
                            !props.alwaysShowCta
                                ? hover
                                    ? { translateY: "var(--product-card-image-translate-y)" }
                                    : { translateY: 0 }
                                : {}
                        }
                    >
                        {props.image ? (
                            <ResponsiveImage
                                image={props.image}
                                width={220}
                                style={{
                                    height: "auto",
                                    width: "auto",
                                    maxHeight: `${props.relativePhysicalSize ?? 100}%`,
                                    maxWidth: "100%",
                                }}
                            />
                        ) : null}
                    </motion.div>
                    <Flex
                        direction="column"
                        alignItems="center"
                        css={css(
                            { marginBottom: 30 },
                            responsiveCss("min", "lg", { marginBottom: 38 }),
                            responsiveCss("min", "xl", { marginBottom: 48 * xlScaleFactor })
                        )}
                    >
                        <Text variant="body" size="xl" margin={{ bottom: 4 }} truncate>
                            {props.name}
                        </Text>
                        <Text variant="body" size="md" color="gray400" truncate>
                            <Flex gap={4}>
                                {props.features.length
                                    ? props.features.slice(0, 2).map((f, i) => (
                                          <>
                                              {i > 0 ? <div>·</div> : null}
                                              <div>{f}</div>
                                          </>
                                      ))
                                    : // Insert an non-breaking space to add the height of this line even if no features.
                                      " "}
                            </Flex>
                        </Text>
                    </Flex>
                    <div>
                        <motion.div
                            animate={
                                hover && !props.alwaysShowCta
                                    ? {
                                          translateY: "var(--product-card-price-translate-y)",
                                      }
                                    : {}
                            }
                            css={css(
                                {
                                    "--product-card-price-translate-y": `-${16 * xlScaleFactor * xlScaleFactor}px`,
                                    transform: props.alwaysShowCta
                                        ? "translateY(var(--product-card-price-translate-y))"
                                        : "",
                                },
                                responsiveCss("min", "lg", {
                                    "--product-card-price-translate-y": `-${16 * xlScaleFactor}px`,
                                }),
                                responsiveCss("min", "xl", {
                                    "--product-card-price-translate-y": "-16px",
                                })
                            )}
                        >
                            <div
                                css={css(
                                    props.highlightPrice && { color: colors.brand },
                                    responsiveBodySize("xl"),
                                    {
                                        fontFamily: "Catalogue",
                                        fontWeight: "600 !important",
                                        textAlign: "center",
                                    }
                                )}
                            >
                                {props.price}
                            </div>
                        </motion.div>
                        <motion.div
                            css={css(
                                {
                                    textAlign: "center",
                                    "--initial-button-opacity": 0,
                                    "--initial-button-transform": `${8 * xlScaleFactor * xlScaleFactor}px`,
                                    "--initial-button-margin-top": "4px",
                                    pointerEvents: props.alwaysShowCta || hover ? "all" : "none",
                                },
                                responsiveCss("min", "lg", {
                                    "--initial-button-transform": `${8 * xlScaleFactor}px`,
                                }),
                                responsiveCss("min", "xl", {
                                    "--initial-button-transform": "8px",
                                }),
                                touchCss({
                                    "--initial-button-opacity": 1,
                                    "--initial-button-transform": "none",
                                    "--initial-button-margin-top": "20px",
                                    pointerEvents: "all",
                                })
                            )}
                            initial={
                                props.alwaysShowCta
                                    ? {
                                          opacity: 1,
                                          translateY: 0,
                                      }
                                    : {
                                          opacity: "var(--initial-button-opacity)",
                                          translateY: "var(--initial-button-transform)",
                                          marginTop: "var(--initial-button-margin-top)",
                                      }
                            }
                            animate={
                                !props.alwaysShowCta && hover
                                    ? {
                                          opacity: 1,
                                          translateY: 0,
                                      }
                                    : {}
                            }
                        >
                            <Button variant="primary" iconEnd="arrowRight">
                                {props.cta.text}
                            </Button>
                        </motion.div>
                    </div>
                </Flex>
            </Flex>
        </MotionConfig>
    )
}

Component(ProductCard, {
    name: "ProductCard",
    gallery: {
        path: "Cards/ProductCard",
        items: [
            {
                variants: [
                    {
                        props: {
                            name: "iPhone 15 Pro",
                            features: ["6,7” Skjerm", "48MP Kamera"],
                            price: "Fra kr 163 /m",
                            cta: {
                                text: "Kjøp nå",
                                url: "/devices/iphone-15-pro",
                            },
                            label: { color: "sky", text: "New" },
                            image: `${server()}/static/redoit/product-card-product-image1.png` as any as Image,
                        },
                    },
                    {
                        props: {
                            name: "iPhone 15 Pro",
                            features: ["6,7” Skjerm", "48MP Kamera"],
                            price: "Fra kr 163 /m",
                            cta: {
                                text: "Kjøp nå",
                                url: "/devices/iphone-15-pro",
                            },
                            label: { color: "brand", text: "Deal" },
                            highlightPrice: true,
                            image: `${server()}/static/redoit/product-card-product-image3.png` as any as Image,
                        },
                    },
                    {
                        props: {
                            name: "iPhone 15 Pro",
                            features: ["6,7” Skjerm", "48MP Kamera"],
                            price: "Fra kr 163 /m",
                            cta: {
                                text: "Kjøp nå",
                                url: "/devices/iphone-15-pro",
                            },
                            image: `${server()}/static/redoit/product-card-product-image2.png` as any as Image,
                        },
                    },
                ],
            },
        ],
    },
})
